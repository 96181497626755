import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent {
  @ViewChild('input', { static: false })
  public inputRef: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('errorMessageRef', { static: true })
  public errorMessageRef: ElementRef<HTMLDivElement> | undefined;

  @Input()
  public id: string = uniqueId('app-checkbox-');

  @Input()
  public dataTestid?: string;

  @Input()
  public label: string = '';

  @Input()
  public color?: string;

  @Input()
  public control: FormControl = new FormControl(false);

  @Input()
  public labelClass: string =
    'block text-sm font-medium leading-6 text-gray-900';

  @Input()
  public inputClass: string =
    'h-10 w-full py-3 px-4 rounded-md border-gray-200 text-gray-900 text-base font-normal leading-4 focus:outline-none';

  @Input()
  public checked: boolean = false;

  @Input()
  public readonly: boolean = false;

  @Input()
  public requiredTag: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean = false;

  @Input()
  public error: boolean = false;

  @Input()
  public errorMessages: Record<string, string> = {};

  @Output()
  public onChange = new EventEmitter<boolean>();

  get hasError(): boolean {
    if (this.error) return true;

    const someError = Object.values(this.control.errors || {}).some(Boolean);

    return (
      someError &&
      this.control.invalid &&
      (this.control.dirty || this.control.touched)
    );
  }

  get errorMessageInternal(): string {
    const key = Object.keys(this.control.errors || {}).find(Boolean);
    return key && this.errorMessages[key] ? this.errorMessages[key] : '';
  }

  get isErrorMessageSlotUsed(): boolean {
    return !!this.errorMessageRef?.nativeElement.innerHTML;
  }

  ngOnInit(): void {
    this.checked = this.control.value;
  }

  onInputChange(val: any): void {
    this.checked = val.target.checked ?? !this.checked;
    this.onChange.emit(val.target.checked);
    this.control.setValue(this.checked);
  }
}
