import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { VerifyUserPasswordService } from '../../../domain/auth/services/verify-user-password.service';

export class VerifyUserPasswordServiceInstruction implements ClassProvider {
  provide = Types.VerifyUserPasswordService;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = VerifyUserPasswordService;
}
