<div class="login-by-icp-page">
  @if (certificates().length > 0) {
  <div class="select-certificates">
    <div class="select-certificates__title">
      Identificamos um ou mais certificados em seu dispositivo
    </div>
    <div class="select-certificates__subtitle">
      Clique abaixo com qual gostaria de entrar
    </div>
    <div class="select-certificates__list">
      @for(certificate of certificates(); track certificate.title) {
      <div
        class="select-certificates__list__item"
        [class.blocked]="certificate.disabled"
        (click)="selectCertificate(certificate)"
      >
        <div class="select-certificates__list__item__picture">
          <app-icon name="badge-check-outline" color="#fff" />
        </div>
        <div class="select-certificates__list__item__details">
          <div
            [title]="certificate.title"
            class="select-certificates__list__item__details__name"
          >
            {{ certificate.title }}
          </div>
          <div class="select-certificates__list__item__details__extra">
            {{ certificate.subtitle }}
          </div>
          <div
            [title]="certificate.type"
            class="select-certificates__list__item__details__extra"
          >
            {{ certificate.type }}
          </div>
        </div>
      </div>
      }
    </div>
    <div class="select-certificates__terms">
      Ao logar no sistema com certificado digital, você concorda com os
      <strong>Termos de uso</strong>
    </div>
  </div>
  }
</div>
