<div class="app-icon" [style]="{ color: color }">
  <svg
    [style]="{ width: size, height: size }"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use
      [attr.xlink:href]="'/assets/icons/' + name + ' .svg#' + name"
      [attr.href]="'/assets/icons/' + name + '.svg#' + name"
    ></use>
  </svg>
</div>
