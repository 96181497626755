<div class="app-personal-form">
  <p class="app-personal-form__informative">
    Preencha os campos abaixo e clique em continuar
  </p>

  @if (config$ | async; as config) { @if (config.isAllowedBusinessAccount) {
  <div class="w-full mb-4">
    <span class="form-label">
      Selecione o tipo de conta que gostaria de criar
    </span>
    <div class="flex flex-row gap-x-5 gap-y-3 w-full mt-4">
      <app-radio
        label="Pessoa física"
        name="accountType"
        value="personal"
        [disabled]="isFieldBlocked('type')"
        [control]="form.controls.type"
      />
      <app-radio
        label="Pessoa jurídica"
        name="accountType"
        value="business"
        [disabled]="isFieldBlocked('type')"
        [control]="form.controls.type"
      />
    </div>
  </div>
  } }

  <div class="create-account-form-row">
    <app-input
      [control]="form.controls.nome"
      [label]="isPersonalAccount ? 'Nome' : 'Razão Social'"
      tabindex="1"
      maxlength="144"
      [required]="true"
      [disabled]="isFieldBlocked('nome')"
      [errorMessages]="{
        required: isPersonalAccount
          ? 'Preencha corretamente o nome.'
          : 'Preencha corretamente a razão social.'
      }"
    />

    <app-input
      [control]="form.controls.sobrenome"
      [label]="isPersonalAccount ? 'Sobrenome' : 'Nome Fantasia'"
      tabindex="2"
      maxlength="144"
      [required]="true"
      [disabled]="isFieldBlocked('sobrenome')"
      [errorMessages]="{
        required: isPersonalAccount
          ? 'Preencha corretamente o sobrenome.'
          : 'Preencha corretamente o nome fantasia.'
      }"
    />
  </div>

  <div class="create-account-form-row">
    <app-input
      class="w-full"
      [control]="form.controls.cpf"
      [label]="isPersonalAccount ? 'CPF' : 'CNPJ'"
      tabindex="3"
      [mask]="isPersonalAccount ? '000.000.000-00' : '00.000.000/0000-00'"
      [required]="true"
      [disabled]="isFieldBlocked('cpf')"
      [errorMessages]="{
        required: isPersonalAccount
          ? 'Por favor preencha o campo CPF.'
          : 'Por favor preenhca o campo CNPJ.',
        cpf: 'Por favor, digite um CPF válido.',
        cnpj: 'Por favor, digite um CNPJ válido.'
      }"
    />
    <app-input
      class="w-full"
      [control]="form.controls.telefone"
      label="Telefone ou Celular"
      tabindex="4"
      [mask]="
        form.controls.telefone.value.length <= 10
          ? '(00) 0000-00009'
          : '(00) 00000-0000'
      "
      [required]="true"
      [disabled]="isFieldBlocked('telefone')"
      [errorMessages]="{
        required: 'Por favor preencha o campo telefone.',
        telefone: 'Por favor, digite um TELEFONE válido.'
      }"
    />
  </div>

  <div class="create-account-form-row">
    <app-input
      [control]="form.controls.email"
      label="E-mail"
      tabindex="5"
      noSpace
      maxlength="255"
      [required]="true"
      [disabled]="isFieldBlocked('email')"
      [errorMessages]="{
        required: 'Por favor preencha o campo de e-mail.',
        email: 'Por favor, digite um E-MAIL válido.'
      }"
    />

    <div class="w-full hidden sm:block"></div>
  </div>
</div>
