import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';

import { City } from '../../domain/city/entities/city.entity';
import { Types } from '../../domain/types.domain';

import { AppHandler } from '../app.handler';

export const cityResolver: ResolveFn<City | null> = async (route, state) => {
  const router = inject(Router);
  const _appHandler = inject(AppHandler);

  const _settings = inject(Types.Settings);
  const getCityByIdentity = inject(Types.GetCityByIdentityService);

  const citySubdomain = route.paramMap.get('city') ?? '';

  const fromCityWhitelist = _settings.citiesWhitelist.find(
    (it) => it.identity === citySubdomain
  );

  if (!fromCityWhitelist) {
    router.navigate(['/']);
    return null;
  }

  let city = _appHandler.getCity();

  if (!city) {
    city = await getCityByIdentity.handle({
      identity: citySubdomain,
    });

    _appHandler.setCity(city);
  }

  return Object.assign(city, {
    connection: fromCityWhitelist.connection,
    loginUrl: fromCityWhitelist.loginUrl,
  });
};
