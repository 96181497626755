<div class="app-checkbox" [class.disabled]="disabled">
  <div class="checkbox-container">
    <input
      [id]="id"
      type="checkbox"
      [checked]="checked"
      [class.app-checkbox-disabled]="disabled"
      [disabled]="disabled"
      (input)="onInputChange($event)"
    />
    <span
      class="checkmark"
      [style.--bg-color]="color"
      (click)="onInputChange($event)"
    >
      @if (checked) {
      <svg
        version="1.1"
        focusable="false"
        viewBox="0 0 24 24"
        xml:space="preserve"
      >
        <path fill="none" stroke="#fff" d="M4.1,12.7 9,17.6 20.3,6.3"></path>
      </svg>
      }
    </span>

    <label [for]="id" class="checkbox-content cursor-pointer">
      <ng-content></ng-content>
    </label>
  </div>

  <div class="box-error-message">
    <div #errorMessageRef>
      <ng-content select="[error-message]"></ng-content>
    </div>

    @if (hasError && !isErrorMessageSlotUsed) {
    <p error-message class="text-sm text-red-600 dark:text-red-500">
      {{ errorMessageInternal }}
    </p>
    }
  </div>
</div>
