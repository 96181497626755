import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @ViewChild('contentRef', { static: true })
  public contentRef: ElementRef<HTMLDivElement> | undefined;

  @Input()
  public id: string = uniqueId('app-button-');

  @Input()
  public buttonType: 'button' | 'submit' = 'button';

  @Input()
  public buttonClass: string = '';

  @Input()
  public label: string = '';

  @Input()
  public loadingLabel: string = 'Carregando';

  @Input()
  public disabled: boolean = false;

  @Input()
  public outline: boolean = false;

  @Input()
  public squared: boolean = false;

  @Input()
  public filled: boolean = false;

  @Input()
  public shadow: boolean = false;

  @Input()
  public isLoading: boolean = false;

  @Output()
  public onClick = new EventEmitter<any>();

  private defaultButtonClass =
    'flex items-center justify-center w-full h-10 text-base leading-6 font-semibold cursor-pointer';

  get buttonClasses(): string {
    return `${this.defaultButtonClass} ${this.buttonClass}`;
  }

  get isContentSlotUsed(): boolean {
    return !!this.contentRef?.nativeElement.innerHTML;
  }

  buttonClick(): void {
    if (this.disabled) return;
    this.onClick.emit();
  }
}
