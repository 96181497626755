import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleButtonComponent } from './google-button.component';
import { GoogleSigninButtonModule, SocialLoginModule } from "@abacritt/angularx-social-login";
import {
  SocialLoginProviderInstruction
} from "../../../builder/instructions/providers/social-login-provider.instruction";



@NgModule({
  declarations: [GoogleButtonComponent],
  exports: [GoogleButtonComponent],
  providers: [
    new SocialLoginProviderInstruction()
  ],
  imports: [
    CommonModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
  ]
})
export class GoogleButtonModule {}
