<div class="login-integration-page">
  @if (requireSelectAccount()) {
  <div class="select-accounts">
    <div class="select-accounts__title">
      Identificamos que você tem mais de uma conta com este CPF
    </div>
    <div class="select-accounts__subtitle">
      Escolha abaixo com qual gostaria de entrar
    </div>
    <div class="select-accounts__list">
      @for(account of accounts(); track account.userId) {
      <div
        class="select-accounts__list__item"
        [class.select-accounts__list__item--blocked]="account.blocked"
        (click)="selectAccountToLogin(account)"
      >
        <div class="select-accounts__list__item-picture">
          {{ getUserAbreviation(account) }}
        </div>
        <div class="select-accounts__list__item-details">
          <div class="select-accounts__list__item-details__name">
            {{ account.name }}
          </div>
          <div class="select-accounts__list__item-details__email">
            {{ account.email }}
          </div>
          <div class="select-accounts__list__item-details__type">
            <span> Conta </span>
            <strong>
              {{ account.internal ? "interna" : "externa" }}
            </strong>
          </div>
        </div>
      </div>
      }
    </div>
  </div>
  }
</div>
