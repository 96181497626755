<div class="app-radio-group" [class.app-radio-group--disabled]="disabled">
  @if (label) {
  <div class="box-label">
    <label
      [for]="id"
      data-testid="dataTestid ? 'textFieldLabel-' + dataTestid : undefined"
      [class]="labelClass"
    >
      {{ label }}
    </label>

    @if (optionalTag) {
    <span class="optional-tag ml-1">(opcional)</span>
    }
  </div>
  }

  <div
    class="app-radio-group__options"
    [class.app-radio-group__options--vertical]="vertical"
  >
    @for(option of radioOptions; track option.value) {
    <app-radio
      [label]="option.label || option.value"
      [name]="name"
      [value]="option.value"
      [disabled]="option.disabled || disabled"
      [checked]="control.value === option.value"
      (onChange)="onValueChange($event)"
    ></app-radio>
    }
  </div>
</div>
