@if (stepperType === 'simple') {
<nav class="app-stepper--simple" aria-label="Progress">
  <ol role="list" class="app-stepper__list">
    @for (step of steps; track step; let i = $index) {
    <li
      class="app-stepper__list__item"
      [class.app-stepper__list__item--active]="activeStep === i"
      [class.app-stepper__list__item--valid]="step.valid"
      [class.hover:border-aprova]="step.valid && activeStep !== i"
      [class.disabled]="!step.valid"
      (click)="goToStep(i)"
    >
      @if (!hideStepNumber) {
      <span
        class="app-stepper__list__item__number"
        [class.text-gray-500]="activeStep < i"
        [class.text-aprova]="activeStep === i"
        [class.group-hover:text-aprova]="step.valid && activeStep !== i"
      >
        Passo {{ i + 1 }}
      </span>
      }
      <span class="app-stepper__list__item__label">{{ step.label }}</span>
    </li>
    }
  </ol>
</nav>
} @else if (stepperType === 'bullets') {
<nav
  class="flex justify-center sm:justify-between items-center"
  aria-label="Progress"
>
  <div class="hidden sm:block" style="min-width: 84px">
    <div
      class="flex items-center gap-x-2 cursor-pointer"
      [class.hidden]="activeStep === 0"
      (click)="goToStep(activeStep - 1)"
    >
      <app-icon name="chevron-left-outline" size="16px" color="#9ca3af" />
      <span class="text-gray-400 text-sm"> Voltar </span>
    </div>
  </div>

  <div class="flex justify-center items-center gap-x-4">
    @if (!hideStepNumber) {
    <p class="text-sm font-medium leading-4" style="min-width: 80px">
      Passo {{ activeStep + 1 }} de {{ steps.length }}
    </p>
    }
    <ol role="list" class="flex justify-between items-center gap-x-5 my-4">
      @for (step of steps; track step; let i = $index) {
      <li class="step-bullets">
        <span
          class="relative flex items-center justify-center"
          [class.disabled]="!step.valid"
          (click)="goToStep(i)"
        >
          @if (activeStep === i) {
          <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
            <span class="h-full w-full rounded-full bg-green-200"></span>
          </span>
          }
          <span
            class="relative block h-2.5 w-2.5 rounded-full"
            [class.bg-aprova]="step.valid || activeStep === i"
            [class.bg-gray-200]="!step.valid || activeStep < i"
            [class.cursor-pointer]="step.valid"
          >
          </span>
        </span>
      </li>
      }
    </ol>
  </div>

  <div class="hidden sm:block" style="min-width: 84px">
    <div
      class="flex items-center gap-x-2"
      [class.hidden]="activeStep === steps.length - 1"
      [class.cursor-pointer]="isActiveStepValid"
      [class.disabled]="!isActiveStepValid || disabled"
      [class.opacity-50]="!isActiveStepValid || disabled"
      (click)="goToStep(activeStep + 1)"
    >
      <span class="text-gray-400 text-sm"> Prosseguir </span>
      <app-icon name="chevron-right-outline" size="16px" color="#9ca3af" />
    </div>
  </div>
</nav>

} @else if (stepperType === 'panels') {
<nav class="mb-6" aria-label="Progress">
  <ol
    role="list"
    class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0"
  >
    @for (step of steps; track step; let i = $index; let isLlast = $last) {
    <li
      class="relative md:flex md:flex-1"
      [class.disabled]="!step.valid"
      (click)="goToStep(i)"
    >
      <div
        class="group flex w-full items-center"
        [class.cursor-pointer]="step.valid || activeStep !== i"
      >
        <span class="flex items-center px-6 py-4 text-sm font-medium">
          <span
            class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full"
            [class.border-2]="!step.valid"
            [class.group-hover:bg-aprova]="step.valid"
            [class.bg-aprova]="step.valid"
            [class.border-sky-600]="activeStep === i"
            [class.border-gray-300]="activeStep < i"
            [class.group-hover:border-gray-400]="activeStep < i"
          >
            @if (!hideStepNumber) { @if (step.valid) {
            <svg
              class="h-6 w-6 text-white"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                clip-rule="evenodd"
              />
            </svg>
            } @else {
            <span
              [class]="
                activeStep === i
                  ? 'text-aprova'
                  : 'text-gray-500 group-hover:text-gray-900'
              "
            >
              {{ padWithLeadingZeros(i + 1) }}
            </span>
            } }
          </span>
          <span
            class="ml-4 text-sm font-medium text-gray-900"
            [class.text-aprova]="activeStep === i"
          >
            {{ step.label }}
          </span>
        </span>
      </div>
      @if (!isLlast) {
      <div
        class="absolute right-0 top-0 hidden h-full w-5 md:block"
        aria-hidden="true"
      >
        <svg
          class="h-full w-full text-gray-300"
          viewBox="0 0 22 80"
          fill="none"
          preserveAspectRatio="none"
        >
          <path
            d="M0 -2L20 40L0 82"
            vector-effect="non-scaling-stroke"
            stroke="currentcolor"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      }
    </li>
    }
  </ol>
</nav>
}
