import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { VerifyCreateAccountTokenServiceInterface } from '../interfaces/services/verify-create-account-token.service.interface';
import { VerifyCreateAccountTokenInput } from '../dtos/verify-create-account-token.input';

@Injectable()
export class VerifyCreateAccountTokenService
  implements VerifyCreateAccountTokenServiceInterface
{
  private readonly _authRepository = inject(Types.AuthRepository);

  async handle(input: VerifyCreateAccountTokenInput) {
    return await this._authRepository.verifyCreateAccountToken(input);
  }
}
