import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { TwoFactorAuthenticationRepositoryInterface } from '../../domain/two-factor/interfaces/repositories/two-facto-authentication.repository.interface';
import { TwoFactorAuthenticationInput } from '../../domain/two-factor/dtos/two-factor-authentication.input';
import { TwoFactorAuthenticationOutput } from '../../domain/two-factor/dtos/two-factor-authentication.output';
import { Types } from '../../domain/types.domain';

@Injectable()
export class TwoFactorAuthenticationHttpRepository
  implements TwoFactorAuthenticationRepositoryInterface
{
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async twoFactorConfirmation(
    input: TwoFactorAuthenticationInput
  ): Promise<TwoFactorAuthenticationOutput> {
    return lastValueFrom(
      this._http.post<TwoFactorAuthenticationOutput>(
        `${this._settings.authenticationUrl.v1}/2fa/confirmation`,
        { ...input }
      )
    );
  }
}
