import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { GetCityByIdentityService } from '../../../domain/city/services/get-city-by-identity.service';

export class GetCityByIdentityServiceInstruction implements ClassProvider {
  provide = Types.GetCityByIdentityService;
  deps = [Types.Settings, Types.CityRepository];
  useClass = GetCityByIdentityService;
}
