import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  standalone: true,
  imports: [],
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input()
  public diameter: string = '64';

  @Input()
  public strokeWidth: string = '10';

  @Input()
  public color: string = '#00AFAA';
}
