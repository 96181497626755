import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { ResetPasswordService } from '../../../domain/auth/services/reset-password.service';

export class ResetPasswordServiceInstruction implements ClassProvider {
  provide = Types.ResetPasswordService;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = ResetPasswordService;
}
