<div class="app-login-by-form">
  @if (backgroudImageUrl()) {
  <div class="background-image">
    <img
      [src]="backgroudImageUrl()"
      alt="background"
      class="w-full h-full object-cover"
    />
  </div>
  } @if (isAccountConfirmed()) {
  <ng-container>
    <div class="success-confirm" role="alert">
      <app-icon
        class="success-confirm__icon"
        [name]="'check-circle-solid'"
        size="58px"
        [color]="'#1AAF50'"
      />
      <span class="success-confirm__title"
        >Parabéns! sua conta foi confirmada com sucesso.
      </span>
      <small class="success-confirm__subtitle">
        Agora basta efetuar seu login abaixo:
      </small>
    </div>
  </ng-container>
  }
  <div class="app-login-by-form__content">
    @if(sessionTimeout()){
    <div
      class="border rounded-lg border-red-500 px-2 py-3 flex space-x-1 mb-11"
    >
      <app-icon
        [name]="'exclamation-circle-outline'"
        size="20px"
        [color]="'#EF4444'"
      />
      <div class="text-red-500 text-sm font-normal">
        Sua sessão expirou. Realize o login novamente.
      </div>
    </div>
    } @if (data$ | async; as data) { @if (!data.loading) {
    <div class="box-login">
      <span class="box-login__title"> Acesse sua conta </span>

      @if (showAuthMethod(data.config, 'CREDENTIALS') && !hideSignUp()) {
      <span class="box-login__subtitle">
        É seu primeiro acesso?
        <a (click)="redirectToCreateAccount()"> Crie sua conta aqui </a>
      </span>
      } @if (showAuthMethod(data.config, 'CREDENTIALS') && subtitle()) {
      <span class="box-login__subtitle">{{ subtitle() }}</span>
      }
    </div>

    @if (showAuthMethod(data.config, 'CREDENTIALS')) {
    <form [formGroup]="form" (submit)="onSubmit()">
      <app-input
        #inputEmail
        id="email"
        height="44px"
        [control]="form.controls.email"
        [label]="identifierConfig[identifierType].label"
        [placeholder]="identifierConfig[identifierType].placeholder"
        [mask]="identifierConfig[identifierType].mask"
        [required]="true"
        [errorMessages]="identifierConfig[identifierType].errorMessages"
        (input)="onFormInput()"
      />

      <app-input
        #inputPassword
        [inputType]="!showPassword() ? 'password' : 'text'"
        height="44px"
        [control]="form.controls.password"
        label="Senha"
        placeholder="Digite sua senha"
        [required]="true"
        [errorMessages]="{
          required: 'Ops! Senha precisa ser preenchida.',
          incorrect: identifierConfig[identifierType].errorMessages.incorrect
        }"
        (input)="onFormInput()"
      >
        <ng-container append>
          <app-icon
            [name]="!showPassword() ? 'eye-off-solid' : 'eye-solid'"
            size="20px"
            color="#9ca3af"
            class="cursor-pointer"
            (click)="toggleInputPassword()"
          />
        </ng-container>
      </app-input>

      <div class="flex justify-between items-center mb-5">
        <app-checkbox
          class="font-normal text-sm leading-4 text-gray-500"
          color="#06929F"
          [checked]="rememberMe()"
          (change)="handleRemembermeChange()"
        >
          Lembrar de mim
        </app-checkbox>
        @if (!hideRecoverPassword()) {
        <div
          class="text-sm leading-6 font-semibold text-aprova hover:text-aprova/80 cursor-pointer"
          (click)="redirectToRecoverPassword()"
        >
          Esqueci minha senha
        </div>
        }
      </div>

      @if (!data.config.hideRecaptcha) {
      <app-recaptcha
        [recaptchaFormControl]="form.controls.recaptchaReactive"
        horizontalPosition="center"
        [errorMessages]="{
          required: 'Esta opção é obrigatória para prosseguir.'
        }"
      />
      @if (!form.controls['recaptchaReactive'].errors &&
      !form.controls['recaptchaReactive'].pristine) {
      <div></div>
      } }

      <aprova-button
        id="btn-login-submit"
        buttonType="submit"
        [disabled]="logginIn()"
        [isLoading]="logginIn()"
        [label]="logginIn() ? 'Carregando' : 'Entrar'"
      />
    </form>
    } @if (hasAlternativeAuthMethods(data.config)) {

    <div class="alternative-logins">
      @if (showAuthMethodsDivider(data.config)) {
      <div class="divider-box">
        <div class="divider-horizontal"></div>
        <div class="divider-text">ou</div>
        <div class="divider-horizontal"></div>
      </div>
      } @if (!showSingleLoginInstructions(data.config)) {
      <div
        class="text-gray-900 text-sm font-normal font-['Inter'] leading-snug"
      >
        Acessar com uma das opções abaixo:
      </div>
      }

      <div class="alternative-logins-actions">
        @if (showAuthMethod(data.config, 'GOOGLE')) {
        <app-google-button
          [width]="362"
          (signIn)="socialLogin($event)"
        ></app-google-button>
        } @if (showGovBrMethod(data.config)) {

        <app-button
          id="btn-login-govbr"
          buttonType="button"
          buttonClass="w-full border-color-google"
          [outline]="true"
          class="w-full"
          (click)="loginGovBr(data.config)"
        >
          <div class="button-content">
            <span>Entrar com</span>
            <img
              src="https://www.gov.br/++theme++padrao_govbr/img/govbr-colorido-b.png"
              alt="gov.br"
              class="w-[62.82px] h-[22.62px]"
            />
          </div>
        </app-button>
        } @if (showAuthMethod(data.config, 'ICP')) {
        <app-button
          id="btn-login-icp"
          buttonType="button"
          buttonClass="w-full border-color-google"
          [outline]="true"
          class="w-full"
          (click)="loginICP()"
        >
          <div class="button-content">
            <span>Entrar com</span>
            <img
              src="/assets/images/icp-brasil.svg"
              alt="ICP"
              class="w-[62.82px] h-[22.62px]"
            />
          </div>
        </app-button>
        }
      </div>

      <div
        class="single-login-instructions"
        [innerHTML]="showSingleLoginInstructions(data.config)"
      ></div>
    </div>
    } @else if(showIntegrationLoginButton(data.config)) {
    <aprova-button
      id="btn-login-integration"
      buttonType="button"
      buttonClass="w-full"
      class="w-full"
      [label]="loginButtonText(data.config)"
      (click)="redirectToIntegrationUrl(data.config)"
    />

    @if(showIntegrationLoginButton(data.config)) {
    <div class="divider-box">
      <div class="divider-horizontal"></div>
      <div class="divider-text">ou</div>
      <div class="divider-horizontal"></div>
    </div>

    <div class="external-login-url-label">
      Digite ou copie o endereço e abra em seu navegador:
    </div>

    <div class="flex gap-x-2 mt-2">
      <div class="external-login-url">
        {{ integrationMethodUrl(data.config) }}
      </div>
      <div class="external-login-url-copy">
        @if (urlCopied()) {
        <app-icon name="check-solid" size="20px" color="#1AAF50" />
        } @else {
        <app-icon
          name="document-duplicate-outline"
          size="20px"
          color="#9ca3af"
          class="cursor-pointer"
          (click)="copyIntegrationMethodUrl(data.config)"
        />
        }
      </div>
    </div>
    } } } }
  </div>
</div>
