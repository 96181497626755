<div
  class="re-captcha-container"
  [class.re-captcha-container--center]="horizontalPosition === 'center'"
  [class.re-captcha-container--right]="horizontalPosition === 'right'"
>
  <div>
    <re-captcha
      [formControl]="recaptchaFormControl"
      [siteKey]="recaptchaSiteKey"
      required
    ></re-captcha>

    <div class="box-error-message">
      @if (hasError) {
      <p class="text-sm text-red-600 dark:text-red-500">
        {{ errorMessageInternal }}
      </p>
      }
    </div>
  </div>
</div>
