import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { ValidateCertificateAuthenticationServiceInterface } from '../interfaces/services/validate-certificate-authentication.service.interface';
import { ValidateCertificateAuthenticationOutput } from '../dtos/validate-certificate-authentication.output';
import { ValidateCertificateAuthenticationInput } from '../dtos/validate-certificate-authentication.input';

@Injectable()
export class ValidateCertificateAuthenticationService
  implements ValidateCertificateAuthenticationServiceInterface
{
  private readonly _certificateRepository = inject(Types.CertificateRepository);

  async handle(
    input: ValidateCertificateAuthenticationInput
  ): Promise<ValidateCertificateAuthenticationOutput> {
    return await this._certificateRepository.validateAuthentication(input);
  }
}
