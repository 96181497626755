import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { TwoFactorAuthenticationServiceInterface } from '../interfaces/services/two-factor-authentication.service.interface';
import { TwoFactorAuthenticationInput } from '../dtos/two-factor-authentication.input';
import { TwoFactorAuthenticationOutput } from '../dtos/two-factor-authentication.output';

@Injectable()
export class TwoFactorAuthenticationService
  implements TwoFactorAuthenticationServiceInterface
{
  private readonly _twoFactorRepository = inject(
    Types.TwoFactorAuthenticationRepository
  );

  async handle(
    input: TwoFactorAuthenticationInput
  ): Promise<TwoFactorAuthenticationOutput> {
    return await this._twoFactorRepository.twoFactorConfirmation(input);
  }
}
