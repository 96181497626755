import { inject, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Observable, Subscription } from "rxjs";

type TitleFn<T> = (component: T) => string | Observable<string>;

export function PageTitleComponent(title: string, fallbackTitle?: string): new () => OnDestroy;
export function PageTitleComponent<T>(title: TitleFn<T>, fallbackTitle?: string): new () => OnDestroy;

export function PageTitleComponent<T>(title: string | TitleFn<T>, fallbackTitle?: string) {
  return class implements OnDestroy {
    private readonly _title = inject(Title);
    private currentTitle: string;
    private titleSubscription?: Subscription;

    constructor() {
      this.currentTitle = this._title.getTitle();
      setTimeout(() => this.setPageTitle());
    }

    private setPageTitle() {
      if (typeof title === 'function') {
        const titleValue = title(this as any);

        if (titleValue instanceof Observable) {
          this.titleSubscription = titleValue.subscribe((title) => {
            this._title.setTitle(title);
          });
        } else {
          this._title.setTitle(titleValue);
        }

        return;
      }

      this._title.setTitle(title);
    }

    ngOnDestroy(): void {
      this._title.setTitle(fallbackTitle ?? this.currentTitle);
      this.titleSubscription?.unsubscribe();
    }
  }
}
