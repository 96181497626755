import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { AuthHttpRepository } from '../../../infrastructure/repositories/auth.http.repository';

export class AuthRepositoryInstruction implements ClassProvider {
  provide = Types.AuthRepository;
  deps = [Types.Settings];
  useClass = AuthHttpRepository;
}
