<div
  class="app-radio-button"
  [class.radio-checked]="checked"
  [class.flex-row-reverse]="leftLabel"
  [class.cursor-not-allowed]="disabled"
>
  <div class="radio-button-container">
    <div class="radio-outer-circle"></div>
    <div class="radio-inner-circle"></div>
    <input
      [id]="id"
      type="radio"
      [name]="name"
      [value]="value"
      [checked]="checked"
      [disabled]="disabled"
      class="h-4 w-4 border-gray-300 text-indigo-600"
      [class.cursor-not-allowed]="disabled"
      (change)="onInputChange($event)"
    />
  </div>
  <label
    [for]="id"
    [class]="'block text-sm font-medium text-gray-900 ' + labelClass"
    [class.cursor-pointer]="!disabled"
    [class.cursor-not-allowed]="disabled"
    [class.opacity-75]="disabled"
  >
    {{ label }}
  </label>
</div>
