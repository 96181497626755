import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { SearchCepProvider } from '../../../infrastructure/providers/search-cep.provider';

export class SearchCepProviderInstruction implements ClassProvider {
  provide = Types.SearchCepProvider;
  deps = [Types.Settings];
  useClass = SearchCepProvider;
}
