import { Component, Input, Output } from '@angular/core';
import { GoogleLoginProvider, SocialAuthService } from "@abacritt/angularx-social-login";
import { filter, switchMap } from "rxjs";

@Component({
  selector: 'app-google-button',
  templateUrl: './google-button.component.html',
  styleUrl: './google-button.component.scss'
})
export class GoogleButtonComponent {

  @Input() width!: number;

  @Output() signIn = this.authService.initState.pipe(
    filter((initialized) => initialized),
    switchMap(() => this.authService.authState),
    filter((user) => user?.provider === GoogleLoginProvider.PROVIDER_ID),
  );

  constructor(
    private readonly authService: SocialAuthService,
  ) {
    void this.authService.signOut();
  }
}
