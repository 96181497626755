import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { RecoverPasswordService } from '../../../domain/auth/services/recover-password.service';

export class RecoverPasswordServiceInstruction implements ClassProvider {
  provide = Types.RecoverPasswordService;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = RecoverPasswordService;
}
