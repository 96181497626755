import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { WebPkiAdapter } from '../../../infrastructure/adapters/web-pki.adapter';

export class WebPkiAdapterInstruction implements ClassProvider {
  provide = Types.WebPkiAdapter;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = WebPkiAdapter;
}
