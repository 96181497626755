import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { Types } from '../../domain/types.domain';
import { City } from '../../domain/city/entities/city.entity';
import { CityRepositoryInterface } from '../../domain/city/interfaces/repositories/city.repository.interface';

@Injectable()
export class CityHttpRepository implements CityRepositoryInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async getByIndex(index: number): Promise<City> {
    return lastValueFrom(
      this._http.get<City>(`${this._settings.citiesUrl.v1}/index/${index}`)
    );
  }

  async getByIdentity(identity: string): Promise<City> {
    return lastValueFrom(
      this._http.get<City>(
        `${this._settings.citiesUrl.v1}/identity/${identity}`
      )
    );
  }
}
