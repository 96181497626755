import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { uniqueId } from 'lodash';
import { ButtonComponent } from '@aprova-digital/design-system';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-box-error',
  standalone: true,
  imports: [IconComponent, ButtonComponent],
  templateUrl: './box-error.component.html',
  styleUrl: './box-error.component.scss',
})
export class BoxErrorComponent {
  @ViewChild('iconRef', { static: true })
  public iconRef: ElementRef<HTMLDivElement> | undefined;

  @Input()
  public id: string = uniqueId('app-box-error-');

  @Input()
  public iconName?: string = 'shield-exclamation-outline';

  @Input()
  public iconColor?: string = '#F43F5F';

  @Input()
  public iconSize?: string = '24px';

  @Input({ required: true })
  public message: string = '';

  @Input()
  public description: string = '';

  @Input()
  public options?: {
    iconBackgroundColor?: string;
    messageClasses?: string;
    messageHorizontalAlign?: 'left' | 'center' | 'right';
    useHtmlInMessage?: boolean;
  } = {
    messageHorizontalAlign: 'left',
  };

  get isIconSlotUsed(): boolean {
    return !!this.iconRef?.nativeElement.innerHTML;
  }

  public safeHtml: SafeHtml = '';

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.safeHtml = this._sanitizer.bypassSecurityTrustHtml(this.description);
  }
}
