import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { uniqueId } from 'lodash';
import { NgxMaskDirective } from 'ngx-mask';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [ReactiveFormsModule, NgxMaskDirective, IconComponent],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent extends FieldType<FormlyFieldConfig> {
  @ViewChild('input', { static: false })
  public inputRef: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('appendRef', { static: true })
  public appendRef: ElementRef<HTMLDivElement> | undefined;

  @ViewChild('errorMessageRef', { static: true })
  public errorMessageRef: ElementRef<HTMLDivElement> | undefined;

  @Input()
  public _id: string = uniqueId('app-input-');

  @Input()
  public dataTestid?: string;

  @Input()
  public inputType: 'text' | 'password' | 'email' | 'number' = 'text';

  @Input()
  public inputMode:
    | 'none'
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url' = 'text';

  @Input()
  public label: string = '';

  @Input()
  public placeholder: string = '';

  @Input()
  public control = new FormControl<string>('');

  @Input()
  public labelClass: string =
    'block text-sm font-medium leading-6 text-gray-900';

  @Input()
  public inputClass: string =
    'w-full h-10 py-3 px-4 text-gray-900 text-base font-normal leading-4 focus:outline-none';

  @Input()
  public width: string = 'auto';

  @Input()
  public height: string = '38px';

  @Input()
  public readonly: boolean = false;

  @Input()
  public squared: boolean = false;

  @Input()
  public filled: boolean = false;

  @Input()
  public shadow: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public clearButton: boolean = false;

  @Input()
  public maxLength: string | number | null = null;

  @Input()
  public mask?: string;

  @Input()
  public required: boolean = false;

  @Input()
  public requiredTag: boolean = false;

  @Input()
  public optionalTag: boolean = false;

  @Input()
  public error: boolean = false;

  @Input()
  public errorMessages: Record<string, string> = {};

  @Output()
  public onFocus = new EventEmitter<FocusEvent>();

  @Output()
  public onBlur = new EventEmitter<FocusEvent>();

  @Output()
  public onInput = new EventEmitter<Event>();

  @Output()
  public onChange = new EventEmitter<Event>();

  public override get id() {
    return this._id;
  }

  public override set id(value: string) {
    this._id = value;
  }

  public hasError = false;
  public errorMessageInternal: string = '';

  get isAppendSlotUsed(): boolean {
    return !!this.appendRef?.nativeElement.innerHTML;
  }

  get isErrorMessageSlotUsed(): boolean {
    return Boolean(this.errorMessageRef?.nativeElement.innerHTML);
  }

  ngOnInit(): void {
    Object.assign(this, this.props);

    if (this.disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });

      if (this.formControl) {
        this.control.setValue(this.formControl.value, { emitEvent: false });
      }

      this.control.valueChanges.subscribe({
        next: (value) => {
          if (this.formControl) this.formControl.setValue(value);
          this.validate();
        },
      });

      this.control.statusChanges.subscribe({
        next: () => {
          this.validate();
        },
      });
    }
  }

  public validate(): void {
    if (this.error) {
      this.hasError = true;
      return;
    }

    if (this.formControl?.errors) {
      this.hasError = true;
      return;
    }

    const hasError = Object.values(this.control.errors || {}).some(Boolean);

    this.hasError =
      hasError &&
      this.control.invalid &&
      (this.control.dirty || this.control.touched);

    if (this.hasError) {
      const key = Object.keys(this.control.errors || {}).find(Boolean);
      this.errorMessageInternal =
        key && this.errorMessages[key] ? this.errorMessages[key] : '';
    }
  }

  public clear(): void {
    this.control.setValue('');
    this.focusInput();
  }

  public focusInput(): void {
    this.inputRef?.nativeElement.focus();
  }
}
