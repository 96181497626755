import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { ButtonComponent } from '@aprova-digital/design-system';

import {
  Rule,
  getPasswordRules,
  getConfirmPasswordRules,
} from '../../../../utils/password.rules';

import { RadioComponent } from '../../../../components/radio/radio.component';
import { InputComponent } from '../../../../components/input/input.component';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';
import { RecaptchaComponent } from '../../../../components/recaptcha/recaptcha.component';
import { IconComponent } from '../../../../components/icon/icon.component';

import { AccountFormHandler } from '../../account-form.handler';

@Component({
  selector: 'app-account-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormlyModule,
    RadioComponent,
    InputComponent,
    CheckboxComponent,
    ButtonComponent,
    RecaptchaComponent,
    IconComponent,
  ],
  templateUrl: './account-form.component.html',
  styleUrl: './account-form.component.scss',
})
export class AccountFormComponent {
  @ViewChild('passwordRulesTooltipRef', { static: true })
  public passwordRulesTooltipRef: ElementRef<HTMLDivElement> | undefined;

  private readonly _accountFormHandler = inject(AccountFormHandler);

  @Input()
  public isLoading: boolean = false;

  @Input()
  public extraFields: FormlyFieldConfig[] = [];

  @Input()
  public hideRecaptcha: boolean = false;

  @Output()
  public setStepValidation = new EventEmitter<boolean>();

  @Output()
  public changeRecaptcha = new EventEmitter<string>();

  public loading = signal(false);
  public hasError = signal(false);
  public errorMessage = signal('');

  public rules: Rule[] = [];
  private _passwordRules: Rule[] = getPasswordRules();
  private _confirmPasswordRules: Rule[] = getConfirmPasswordRules();

  public showPassword = signal(false);
  public showConfirmPassword = signal(false);
  public passwordsMatch = signal(false);

  public form = new FormGroup({
    password: new FormControl('', {
      nonNullable: true,
    }),
    confirmPassword: new FormControl('', {
      nonNullable: true,
    }),
    termos: new FormControl(false, {
      nonNullable: true,
      validators: [Validators.requiredTrue],
    }),
    recaptchaReactive: new FormControl('', {
      nonNullable: true,
    }),
  });

  public formDynamic: FormGroup = new FormGroup({});
  public formDynamicModel: any = {};
  public formDynamicOptions: FormlyFormOptions = {};

  get isPasswordValid(): boolean {
    return this._passwordRules.every((rule) => rule.valid);
  }

  get isConfirmPasswordValid(): boolean {
    return this._confirmPasswordRules.every((rule) => rule.valid);
  }

  ngOnInit(): void {
    if (!this.hideRecaptcha) {
      this.form.controls.recaptchaReactive.setValidators([Validators.required]);
    }

    this.form.controls.password.setValidators([Validators.required]);
    this.form.controls.confirmPassword.setValidators([Validators.required]);
    this.form.setValidators([this.checkPasswords.bind(this)]);

    this.form.valueChanges.subscribe((val) => {
      this._accountFormHandler.setAccountInfo({
        senha: this.form.controls.password.value,
        termos: this.form.controls.termos.value,
      });

      this.setStepValidation.emit(this.form.valid && this.formDynamic.valid);

      if (val.recaptchaReactive) {
        this.changeRecaptcha.emit(val.recaptchaReactive);
      }
    });

    const accountData = this._accountFormHandler.getAccountInfo();
    if (accountData) {
      this.form.patchValue({
        password: accountData['senha'],
        confirmPassword: accountData['senha'],
        termos: accountData['termos'],
      });
    }

    this.initExtraInfoForm();
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    const isPasswordFieldsActive = ['password', 'confirmPassword'].includes(
      document.activeElement?.id ?? ''
    );

    if (this.passwordRulesTooltipRef && isPasswordFieldsActive) {
      const input = document.activeElement?.id as
        | 'password'
        | 'confirmPassword';
      this.hidePasswordRulesTooltip();
      this.showPasswordRulesTooltip(input, {
        target: document.activeElement,
      } as FocusEvent);
    }
  }

  toggleInputPassword(isConfirm: boolean = false): void {
    if (isConfirm) {
      this.showConfirmPassword.update((val) => !val);
    } else {
      this.showPassword.update((val) => !val);
    }
  }

  showPasswordRulesTooltip(
    input: 'password' | 'confirmPassword',
    event: FocusEvent
  ): void {
    if (this.passwordRulesTooltipRef) {
      if (input === 'confirmPassword') {
        this.rules = this._confirmPasswordRules;
        this.validateConfirmPasswordRules();
      } else {
        this.rules = this._passwordRules;
        this.validatePasswordRules();
      }

      const target = event.target as HTMLInputElement;
      const isPortrait = window.innerHeight > window.innerWidth;
      const isPortraitClassName = 'box-password-rules--portrait';

      if (isPortrait) {
        const top =
          (target.parentElement?.offsetTop ?? 0) +
          (target.parentElement?.clientHeight ?? 0);
        const left = target.parentElement?.offsetLeft ?? 0;
        this.passwordRulesTooltipRef.nativeElement.classList.add(
          isPortraitClassName
        );
        this.passwordRulesTooltipRef.nativeElement.style.top = `${top + 20}px`;
        this.passwordRulesTooltipRef.nativeElement.style.display = 'block';
        this.passwordRulesTooltipRef.nativeElement.style.left = `${left}px`;
        return;
      }

      const top = target.parentElement?.offsetTop ?? 0;
      const left = target.parentElement?.offsetLeft ?? 0;
      this.passwordRulesTooltipRef.nativeElement.classList.remove(
        isPortraitClassName
      );
      this.passwordRulesTooltipRef.nativeElement.style.top = `${top - 20}px`;
      this.passwordRulesTooltipRef.nativeElement.style.left = `${left - 380}px`;
      this.passwordRulesTooltipRef.nativeElement.style.display = 'block';
    }
  }

  hidePasswordRulesTooltip(): void {
    if (this.passwordRulesTooltipRef) {
      this.passwordRulesTooltipRef.nativeElement.style.display = 'none';
    }
  }

  validatePasswordRules(): void {
    const password = this.form.controls['password'].value;
    const confirmPassword = this.form.controls['confirmPassword'].value;
    this.form.controls.password.setErrors(null);

    this._passwordRules.forEach((rule) => {
      if (rule.regex) {
        rule.valid = new RegExp(rule.regex, 'g').test(password);
      }

      if (!rule.valid) {
        this.form.controls['password'].setErrors({
          [rule.key]: true,
        });
      }
    });

    if (confirmPassword) {
      this.validateConfirmPasswordRules();
    }
  }

  validateConfirmPasswordRules(): void {
    const password = this.form.controls['password'].value;
    const confirmPassword = this.form.controls['confirmPassword'].value;
    this.form.controls.confirmPassword.setErrors(null);

    this._confirmPasswordRules.forEach((rule) => {
      rule.valid = Boolean(
        password && confirmPassword && password === confirmPassword
      );

      if (!rule.valid) {
        this.form.controls['confirmPassword'].setErrors({
          [rule.key]: true,
        });
      }
    });
  }

  private initExtraInfoForm(): void {
    if (this.extraFields && this.extraFields.length > 0) {
      this.formDynamic.valueChanges.subscribe(() => {
        this.setExtraInfo();
        this.setStepValidation.emit(this.form.valid && this.formDynamic.valid);
      });

      const extraInfo = this._accountFormHandler.getExtraInfo();

      for (const field of this.extraFields) {
        if (extraInfo) {
          this.formDynamic.addControl(
            field.key as string,
            new FormControl(
              extraInfo['extraValue'][field.key as string] ?? '',
              field.props?.required
                ? {
                    validators: [Validators.required],
                    nonNullable: true,
                  }
                : null
            )
          );
          this.formDynamicModel[field.key as string] =
            extraInfo['extraValue'][field.key as string];
        }
      }

      if (extraInfo) this.formDynamic.updateValueAndValidity();
    }
  }

  private setExtraInfo(): void {
    if (this.extraFields && this.extraFields.length > 0) {
      const cache: Record<string, any>[] = [];
      const extraFields = JSON.stringify(
        this.extraFields.map((item) => ({
          key: item.key,
          type: item.type,
          templateOptions: item.props,
        })),
        function (key, value) {
          if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
              // Duplicate reference found, discard key
              return;
            }
            // Store value in our collection
            cache.push(value);
          }
          return value;
        }
      );

      const extraValue = Object.keys(this.formDynamic.value)
        .map((itemKey, index) => {
          return {
            key: this.extraFields[index]?.key,
            value: this.formDynamic.value[itemKey],
          };
        })
        .reduce(
          (prev, curr) => ({ ...prev, [curr.key as string]: curr.value }),
          {}
        );

      this._accountFormHandler.setExtraInfo({
        extraFields,
        extraValue,
      });
    }
  }

  private checkPasswords(
    control: AbstractControl
  ): { [key: string]: any } | null {
    this.validatePassword(control.get('password')?.value);
    const pass = control.get('password')?.value;
    const confirmPass = control.get('confirmPassword')?.value;
    this.passwordsMatch.set(!!pass && !!confirmPass && pass === confirmPass);
    return pass === confirmPass ? null : { notSame: true };
  }

  private validatePassword(password: string): void {
    this._passwordRules.forEach((rule) => {
      rule.valid = new RegExp(rule.regex, 'g').test(password);
    });
  }
}
