<div class="app-address-form">
  <p class="app-address-form__informative">
    Preencha os campos abaixo e clique em continuar
  </p>

  <div class="create-account-form-row">
    <app-input
      [control]="form.controls.cep"
      class="w-full"
      label="CEP"
      tabindex="1"
      mask="00000-000"
      [required]="true"
      [disabled]="isFieldBlocked('cep')"
    />

    <app-input
      #inputStreet
      [control]="form.controls.rua"
      class="w-full"
      label="Rua"
      tabindex="2"
      [required]="true"
      maxlength="255"
    />
  </div>

  <div class="create-account-form-row">
    <app-input
      #inputNumber
      inputType="number"
      inputMode="numeric"
      class="w-full"
      [control]="form.controls.numero"
      label="Número"
      placeholder="EX: 2420"
      tabindex="3"
      maxlength="144"
      [required]="!noNumberAddress()"
      [optionalTag]="noNumberAddress()"
      [disabled]="isFieldBlocked('numero')"
    >
      <ng-container append>
        <div
          class="no-number-checkbox"
          matTooltip="Não possui número? Marque S/N e escreva no complemento"
          (click)="$event.preventDefault(); toogleNoNumberAddress()"
        >
          <span>S/N</span>
          <app-checkbox
            [checked]="noNumberAddress()"
            (change)="toogleNoNumberAddress()"
          >
          </app-checkbox>
        </div>
      </ng-container>
    </app-input>

    <app-input
      [control]="form.controls.complemento"
      class="w-full"
      label="Complemento"
      placeholder="Ex: Casa 125-B, Br-153, Km 4"
      tabindex="4"
      [required]="noNumberAddress()"
      [optionalTag]="!noNumberAddress()"
      [disabled]="isFieldBlocked('complemento')"
      [errorMessages]="{
        required: 'Preencha corretamente seu complemento.'
      }"
    />
  </div>

  <div class="create-account-form-row">
    <app-input
      #inputNeighborhood
      [control]="form.controls.bairro"
      class="w-full"
      label="Bairro"
      tabindex="5"
      [required]="true"
      [disabled]="isFieldBlocked('bairro')"
      [errorMessages]="{
        required: 'Preencha corretamente seu bairro.'
      }"
    />

    <app-input
      [control]="form.controls.cidade"
      class="w-full"
      label="Cidade"
      tabindex="6"
      [required]="true"
      [disabled]="isFieldBlocked('cidade')"
      [errorMessages]="{
        required: 'Preencha corretamente sua cidade.'
      }"
    />
  </div>

  <div class="create-account-form-row">
    <app-input
      [control]="form.controls.estado"
      class="w-full"
      label="Estado"
      tabindex="7"
      [required]="true"
      [disabled]="isFieldBlocked('estado')"
      [errorMessages]="{
        required: 'Preencha corretamente seu estado.'
      }"
    />

    <div class="w-full hidden sm:block"></div>
  </div>

  @if (isLastStep) {
  <app-checkbox
    [control]="form.controls.termos"
    class="font-normal text-sm leading-4 text-gray-500"
    color="#06929F"
  >
    Li e aceito os
    <a
      href="https://assets-publicos.s3.amazonaws.com/Termos+de+Uso.pdf"
      target="_blank"
    >
      <b> termos de uso</b>
    </a>
  </app-checkbox>

  @if(!hideRecaptcha){
  <app-recaptcha [recaptchaFormControl]="form.controls.recaptchaReactive" />
  } }
</div>
