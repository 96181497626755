import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { filter } from 'rxjs';

import { Types } from '../../../domain/types.domain';

@Component({
  selector: 'app-select-city',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './select-city.component.html',
  styleUrl: './select-city.component.scss',
})
export class SelectCityComponent {
  private readonly router = inject(Router);
  private readonly _settings = inject(Types.Settings);

  public citiesList = this._settings.citiesWhitelist;
  public citiesListSorted = [];
  public cityFormControl = new FormControl('');

  get listSorted() {
    return this.citiesList.sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnInit(): void {
    this.cityFormControl.valueChanges
      .pipe(filter(Boolean))
      .subscribe((value) => {
        const city = this.citiesList.find((city) => city.identity === value);
        if (city?.loginUrl) {
          window.location.href = city.loginUrl;
          return;
        }

        this.router.navigate([`/${value}`]);
      });
  }
}
