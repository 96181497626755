<div class="recover-password-page">
  <div class="recover-password-form">
    <form class="space-y-6" [formGroup]="form" (submit)="onSubmit()">
      <app-input
        id="email"
        inputType="email"
        inputClass="h-11"
        [control]="form.controls.email"
        label="E-mail"
        placeholder="Digite o seu e-mail"
        [required]="true"
        [errorMessages]="{
        required: 'Ops! Email precisa ser preenchido!',
        email: 'Ops! Email invalido!',
      }"
      />

      <div class="flex justify-start items-center">
        <div
          class="text-sm leading-6 font-semibold text-aprova hover:text-aprova/80 cursor-pointer"
          (click)="redirectToLogin()"
        >
          Voltar ao login
        </div>
      </div>

      @if (data$ | async; as data) { @if(!data.hideRecaptcha) {
      <app-recaptcha [recaptchaFormControl]="form.controls.recaptchaReactive" />
      } }
      <aprova-button
        id="btn-recover-password-submit"
        buttonType="button"
        class="inline-flex w-full h-10"
        buttonClass="inline-flex items-center justify-center rounded-md bg-aprova px-3 md:py-1.5 py-3 sm:text-sm leading-6 text-white shadow-sm hover:bg-aprova/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        [isLoading]="loading()"
        label="Recuperar senha"
        loadingLabel="Enviando e-mail"
        (click)="onSubmit()"
      />
    </form>
  </div>
</div>
