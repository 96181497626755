import { Routes } from '@angular/router';

import { SettingsInstruction } from '../builder/instructions/settings/settings.instruction';
import { CityRepositoryInstruction } from '../builder/instructions/repositories/city.repository.instructions';
import { GetCityByIdentityServiceInstruction } from '../builder/instructions/services/get-city-by-identity.service.instruction';

import { cityResolver } from './utils/city.resolver';

import { SelectCityComponent } from './pages/select-city/select-city.component';
import { MainLayoutComponent } from './layouts/main-layout/main.layout.component';

import { CreateAccountComponent } from './pages/create-account/create-account.component';

import { AppHandler } from './app.handler';
import { LoginByFormComponent } from './pages/login-by-form/login-by-form.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { TwoFactorAuthenticationComponent } from './pages/two-factor-authentication/two-factor-authentication.component';
import { LoginByIcpComponent } from './pages/login-by-icp/login-by-icp.component';
import { LoginIntegrationComponent } from './pages/login-integration/login-integration.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { CompleteRegistrationComponent } from './pages/complete-registration/complete-registration.component';

export const routes: Routes = [
  {
    path: '',
    component: SelectCityComponent,
  },
  {
    path: ':city',
    component: MainLayoutComponent,
    providers: [
      AppHandler,
      new SettingsInstruction(),
      new CityRepositoryInstruction(),
      new GetCityByIdentityServiceInstruction(),
    ],
    resolve: {
      city: cityResolver,
    },
    children: [
      {
        path: '',
        component: LoginByFormComponent,
      },
      {
        path: 'recover-password',
        component: RecoverPasswordComponent,
      },
      {
        path: 'two-factor-authentication',
        component: TwoFactorAuthenticationComponent,
      },
      {
        path: 'icp',
        component: LoginByIcpComponent,
      },
      {
        path: 'callback',
        component: LoginIntegrationComponent,
      },
      {
        path: 'callback/:type/:code',
        component: LoginIntegrationComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
      },
      {
        path: 'create-account',
        component: CreateAccountComponent,
      },
      {
        path: 'complete-registration',
        component: CompleteRegistrationComponent,
      },
    ],
  },
];
