import { Component, Input } from '@angular/core';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
})
export class IconComponent {
  @Input()
  public id: string = uniqueId('app-icon-');

  @Input({ required: true })
  public name: string = '';

  @Input()
  public color: string = '#111827';

  @Input()
  public size: string = '24px';
}
