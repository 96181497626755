<div class="two-factor-authentication">
  <div>
    <p class="two-factor-authentication__title">
      Autenticação multifator detectada
    </p>
    <p class="two-factor-authentication__subtitle">
      Você possui uma autenticação multifator ativa via
      <strong>{{ twoFactorAuthenticationName }}.</strong>
      Insira o código gerado no app para acessar sua conta.
    </p>

    <app-input
      #verificationCodeInput
      label="Insira seu código abaixo"
      placeholder="Digite o código"
      [control]="verificationCodeFormControl"
      inputClass="text-2xl text-center"
    />
  </div>

  <aprova-button
    label="Confirmar"
    buttonClass="inline-flex items-center justify-center bg-aprova px-3 md:py-1.5 py-3 sm:text-sm leading-6 text-white hover:bg-aprova/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    [disabled]="!verificationCodeFormControl.valid"
    (click)="confirm()"
  />
</div>
