<div class="app-select">
  @if (label) {
  <div class="app-select__box-label">
    <label
      [for]="id"
      data-testid="dataTestid ? 'textFieldLabel-' + dataTestid : undefined"
      [class]="labelClass"
    >
      {{ label }}
    </label>

    @if (optionalTag) {
    <span class="optional-tag ml-1">(opcional)</span>
    }
  </div>
  }

  <div
    class="app-select__backdrop"
    [style.display]="isOpen() ? 'block' : 'none'"
    (click)="$event.stopPropagation(); closeMenu()"
  ></div>

  <div
    class="app-select__content"
    [class.border]="!filled"
    [class.filled]="filled"
    [class.disabled]="disabled"
    [class.input-error]="hasError"
    [class.rounded-lg]="!squared"
    [style.width]="width || 'auto'"
    [style.height]="height || '38px'"
  >
    <button
      type="button"
      class=""
      [class.rounded-lg]="!squared"
      (click)="onHandleMenuOpen()"
    >
      <span
        class="block text-gray-900 text-base font-normal leading-4 focus:outline-none truncate"
      >
        {{ selectedLabel }}
      </span>
      <span
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
      >
        <svg
          class="h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </button>

    <ul
      [class.hidden]="!isOpen()"
      class="app-select__content__menu shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      tabindex="-1"
    >
      @for (option of selectOptions; track option.value; let index = $index) {
      <li
        [id]="id + 'option-' + index"
        class="app-select__content__menu__item"
        [class.app-select__content__menu__item--selected]="
          option.value === control.value
        "
        [class.app-select__content__menu__item--disabled]="option.disabled"
        (click)="onSelectOption(option)"
      >
        <span class="app-select__content__menu__item__text">
          {{ option.label || option.value }}
        </span>
      </li>
      }
    </ul>
  </div>
</div>
