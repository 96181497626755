export function buildEncodedState(customUrlRedirect: string, strategy: string) {
  const stateParams = {
    ...(customUrlRedirect ? { customUrlRedirect } : {}),
    ...(strategy ? { strategy } : {})
  }
  return btoa(JSON.stringify(stateParams));
}

export function decodeState(encodedState: string) {
  try {
    const state = atob(encodedState);
    return JSON.parse(state);
  } catch (err) {
    console.log('Erro ao decodificar state: ', { err, encodedState });
    return {};
  }
}