import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { VerifyUserPasswordServiceInterface } from '../interfaces/services/verify-user-password.service.interface';
import { VerifyUserPasswordInput } from '../dtos/verify-user-password.input';

@Injectable()
export class VerifyUserPasswordService
  implements VerifyUserPasswordServiceInterface
{
  private readonly _authRepository = inject(Types.AuthRepository);

  async handle(input: VerifyUserPasswordInput) {
    return await this._authRepository.verifyPassword(input);
  }
}
