import {
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@aprova-digital/design-system';
import { delay, map, switchMap, tap } from 'rxjs';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import { Types } from '../../../../../domain/types.domain';
import { AuthRepositoryInstruction } from '../../../../../builder/instructions/repositories/auth.repository.instructions';

import { RadioComponent } from '../../../../components/radio/radio.component';
import { InputComponent } from '../../../../components/input/input.component';

import { AccountFormHandler } from '../../account-form.handler';
import { AppHandler } from '../../../../app.handler';

@Component({
  selector: 'app-personal-form',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    RadioComponent,
    InputComponent,
    ButtonComponent,
  ],
  providers: [new AuthRepositoryInstruction()],
  templateUrl: './personal-form.component.html',
  styleUrl: './personal-form.component.scss',
})
export class PersonalFormComponent {
  private readonly _route = inject(ActivatedRoute);
  private readonly _appHandler = inject(AppHandler);

  private readonly _accountFormHandler = inject(AccountFormHandler);

  private readonly _authRepository = inject(Types.AuthRepository);

  @Input()
  public isLoading: boolean = false;

  @Input()
  public blockedFields: string[] = [];

  @Output()
  public setStepValidation = new EventEmitter<boolean>();

  protected readonly config$ = this._route.data.pipe(
    switchMap(({ city }) => this._authRepository.getConfigByCityId(city.index)),
    map((config) => {
      return {
        isAllowedBusinessAccount: config.allowUseCnpj,
      };
    }),
    delay(750),
    tap(() => {
      const profileData = this._accountFormHandler.getProfileInfo();
      if (profileData) {
        this.form.patchValue(profileData);
      }
      this._appHandler.setLoading(false);
    })
  );

  public loading = signal(false);
  public hasError = signal(false);

  public form = new FormGroup({
    type: new FormControl('personal', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    nome: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    sobrenome: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(144)],
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    }),
    cpf: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    telefone: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  get isPersonalAccount(): boolean {
    return this.form?.controls.type.value === 'personal';
  }

  ngOnInit(): void {
    this.form.controls.email.setValidators([
      this.validateEmail,
      Validators.required,
    ]);

    this.form.controls.cpf.setValidators([
      this.validateDocumentFn,
      Validators.required,
    ]);

    this.form.controls.telefone.setValidators([
      this.validatePhone,
      Validators.required,
    ]);

    this.form.valueChanges.subscribe(() => {
      this.setStepValidation.emit(this.form.valid);
      const profileData = this._accountFormHandler.getProfileInfo() ?? {};
      this._accountFormHandler.setProfileInfo({
        ...profileData,
        ...this.form.value,
      });
    });
  }

  isFieldBlocked(name: string): boolean {
    return this.blockedFields.includes(name);
  }

  private validateDocumentFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const key: 'cpf' | 'cnpj' = this.isPersonalAccount ? 'cpf' : 'cnpj';
    const instance: typeof cpf | typeof cnpj = this.isPersonalAccount
      ? cpf
      : cnpj;
    return instance.isValid(control.value) ? null : { [key]: true };
  };

  private validatePhone = (control: AbstractControl) => {
    return control.value.length >= 10 ? null : { telefone: true };
  };

  private validateEmail = (control: AbstractControl) => {
    if (!control.value) return null;

    const regex = /\S+@\S+\.\S+/;
    return regex.test(control.value) ? null : { email: true };
  };
}
