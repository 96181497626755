import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { UpdateAccountService } from '../../../domain/user/services/update-account.service';

export class UpdateAccountServiceInstruction implements ClassProvider {
  provide = Types.UpdateAccountService;
  deps = [Types.Settings, Types.UserRepository];
  useClass = UpdateAccountService;
}
