<div class="app-spinner">
  <svg
    [style.width.px]="diameter"
    [style.height.px]="diameter"
    viewBox="0 0 100 100"
    class="spinner"
    [attr.color]="color"
  >
    <defs>
      <linearGradient
        id="linear"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
        gradientTransform="rotate(10)"
      >
        <stop offset="10%" stop-color="currentColor" />
        <stop offset="100%" stop-color="whitesmoke" />
      </linearGradient>
    </defs>

    <path
      d="M 50 10 A 40 40 0 1 0 90 50"
      stroke="url(#linear)"
      [attr.stroke-width]="strokeWidth"
      fill="none"
      stroke-linecap="round"
    />
  </svg>
</div>
