import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { VerifyChangePasswordTokenServiceInterface } from '../interfaces/services/verify-change-password-token.service.interface';
import { VerifyChangePasswordTokenInput } from '../dtos/verify-change-password-token.input';

@Injectable()
export class VerifyChangePasswordTokenService
  implements VerifyChangePasswordTokenServiceInterface
{
  private readonly _authRepository = inject(Types.AuthRepository);

  async handle(input: VerifyChangePasswordTokenInput) {
    return await this._authRepository.verifyChangePasswordToken(input);
  }
}
