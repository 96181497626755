import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { CertificateHttpRepository } from '../../../infrastructure/repositories/certificate.http.repository';

export class CertificateRepositoryInstruction implements ClassProvider {
  provide = Types.CertificateRepository;
  deps = [Types.Settings];
  useClass = CertificateHttpRepository;
}
