import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { Types } from '../../domain/types.domain';

import { CertificateRepositoryInterface } from '../../domain/certificate/interfaces/repositories/certificate.repository.interface';
import { ValidateCertificateAuthenticationInput } from '../../domain/certificate/dtos/validate-certificate-authentication.input';
import { ValidateCertificateAuthenticationOutput } from '../../domain/certificate/dtos/validate-certificate-authentication.output';

@Injectable()
export class CertificateHttpRepository
  implements CertificateRepositoryInterface
{
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async validateAuthentication(
    input: ValidateCertificateAuthenticationInput
  ): Promise<ValidateCertificateAuthenticationOutput> {
    return lastValueFrom(
      this._http.post<ValidateCertificateAuthenticationOutput>(
        `${this._settings.authenticationUrl.v1}/certificate/validate`,
        input
      )
    );
  }
}
