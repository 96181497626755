import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, lastValueFrom, of } from 'rxjs';

import { Types } from '../../domain/types.domain';

import { AuthRepositoryInterface } from '../../domain/auth/interfaces/repositories/auth.repository.interface';
import { LoginByFormInput } from '../../domain/auth/dtos/login-by-form.input';
import { LoginByFormOutput } from '../../domain/auth/dtos/login-by-form.output';
import { LoginByIntegrationInput } from '../../domain/auth/dtos/login-by-integration.input';
import { LoginByIntegrationOutput } from '../../domain/auth/dtos/login-by-integration.output';
import { RecoverPasswordInput } from '../../domain/auth/dtos/recover-password.input';
import { VerifyChangePasswordTokenInput } from '../../domain/auth/dtos/verify-change-password-token.input';
import { VerifyUserPasswordInput } from '../../domain/auth/dtos/verify-user-password.input';
import { ResetPasswordInput } from '../../domain/auth/dtos/reset-password.input';
import { ResendAccountConfirmationInput } from '../../domain/auth/dtos/resend-account-confirmation.input';
import { VerifyCreateAccountTokenInput } from '../../domain/auth/dtos/verify-create-account-token.input';
import { VerifyCreateAccountTokenOutput } from '../../domain/auth/dtos/verify-create-account-token.output';
import { GetConfigByCityIdOutput } from '../../domain/auth/dtos/get-config-by-city-id.output';

@Injectable()
export class AuthHttpRepository implements AuthRepositoryInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async loginByForm(input: LoginByFormInput): Promise<LoginByFormOutput> {
    return lastValueFrom(
      this._http.post<LoginByFormOutput>(
        `${this._settings.authenticationUrl.v1}/oauth/token`,
        {
          ...input,
          uiLocales: input.uiLocales ?? 'pt-br',
          username: input.email,
          isFromModal: false,
        }
      )
    );
  }

  async loginByIntegration(
    input: LoginByIntegrationInput
  ): Promise<LoginByIntegrationOutput> {
    return lastValueFrom(
      this._http.post<LoginByIntegrationOutput>(
        `${this._settings.authenticationUrl.v1}/oauth/integration`,
        {
          ...input,
        }
      )
    );
  }

  async recoverPassword(input: RecoverPasswordInput): Promise<void> {
    return lastValueFrom(
      this._http.post<void>(
        `${this._settings.authenticationUrl.v1}/password/recover`,
        {
          ...input,
          uiLocales: input.uiLocales ?? 'pt-br',
        }
      )
    );
  }

  async verifyChangePasswordToken(
    input: VerifyChangePasswordTokenInput
  ): Promise<boolean> {
    return lastValueFrom(
      this._http.post<boolean>(
        `${this._settings.authenticationUrl.v1}/password/token/verify`,
        input
      )
    );
  }

  async verifyPassword(input: VerifyUserPasswordInput): Promise<boolean> {
    return lastValueFrom(
      this._http.post<boolean>(
        `${this._settings.authenticationUrl.v1}/password/verify`,
        input
      )
    );
  }

  async resetPassword(input: ResetPasswordInput): Promise<void> {
    return lastValueFrom(
      this._http.post<void>(
        `${this._settings.authenticationUrl.v1}/password/reset`,
        {
          ...input,
          uiLocales: input.uiLocales ?? 'pt-br',
        }
      )
    );
  }

  async resendAccountConfirmation(
    input: ResendAccountConfirmationInput
  ): Promise<boolean> {
    return lastValueFrom(
      this._http.post<boolean>(
        `${this._settings.authenticationUrl.v1}/send-account-confirmation`,
        input
      )
    );
  }

  async verifyCreateAccountToken(
    input: VerifyCreateAccountTokenInput
  ): Promise<VerifyCreateAccountTokenOutput> {
    return lastValueFrom(
      this._http.post<VerifyCreateAccountTokenOutput>(
        `${this._settings.authenticationUrl.v1}/verify-create-account-token`,
        input
      )
    );
  }

  async getConfigByCityId(input: number): Promise<GetConfigByCityIdOutput> {
    return lastValueFrom(
      this._http
        .get<GetConfigByCityIdOutput>(
          `${this._settings.authenticationUrl.v1}/auth-config/${input}`
        )
        .pipe(catchError(() => of({} as GetConfigByCityIdOutput)))
    );
  }
}
