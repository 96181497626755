<div class="app-complete-registration">
  <app-stepper
    [steps]="steps"
    [activeStep]="currentStep"
    (onStepChange)="goToStep($event)"
  />

  <div class="app-complete-registration__content">
    @switch(currentStep) { @case(0) {
    <div class="app-personal-form">
      <p class="app-personal-form__informative">
        Preencha os campos abaixo e clique em continuar
      </p>

      <div class="complete-registration-form-row">
        <app-input
          [control]="profileForm.controls.firstName"
          [label]="isPersonalAccount ? 'Nome' : 'Razão Social'"
          tabindex="1"
          maxlength="144"
          [required]="true"
          [disabled]="isFieldBlocked('nome')"
          [errorMessages]="{
            required: isPersonalAccount
              ? 'Preencha corretamente o nome.'
              : 'Preencha corretamente a razão social.'
          }"
        />

        <app-input
          [control]="profileForm.controls.lastName"
          [label]="isPersonalAccount ? 'Sobrenome' : 'Nome Fantasia'"
          tabindex="2"
          maxlength="144"
          [required]="true"
          [disabled]="isFieldBlocked('sobrenome')"
          [errorMessages]="{
            required: isPersonalAccount
              ? 'Preencha corretamente o sobrenome.'
              : 'Preencha corretamente o nome fantasia.'
          }"
        />
      </div>

      <div class="complete-registration-form-row">
        <app-input
          [control]="profileForm.controls.cpf"
          [label]="isPersonalAccount ? 'CPF' : 'CNPJ'"
          tabindex="3"
          [mask]="isPersonalAccount ? '000.000.000-00' : '00.000.000/0000-00'"
          [required]="true"
          [disabled]="isFieldBlocked('cpf')"
        />

        <app-input
          [control]="profileForm.controls.phoneNumber"
          label="Telefone ou Celular"
          tabindex="4"
          [mask]="
            profileForm.controls.phoneNumber.value.length <= 10
              ? '(00) 0000-00009'
              : '(00) 00000-0000'
          "
          [required]="true"
          [disabled]="isFieldBlocked('telefone')"
          [errorMessages]="{
            required: 'Por favor preencha o campo telefone.',
            telefone: 'Por favor, digite um TELEFONE válido.'
          }"
        />
      </div>

      <div class="complete-registration-form-row">
        <app-input
          [control]="profileForm.controls.email"
          label="E-MAIL"
          tabindex="5"
          maxlength="255"
          [disabled]="isFieldBlocked('email')"
        />

        <div class="w-full hidden sm:block"></div>
      </div>
    </div>
    } @case (1) {
    <div class="app-address-form">
      <p class="app-address-form__informative">
        Preencha os campos abaixo e clique em atualizar
      </p>

      <div class="complete-registration-form-row">
        <app-input
          #inputCep
          [control]="addressForm.controls.cep"
          label="CEP"
          tabindex="1"
          mask="00000-000"
          [required]="true"
          [disabled]="isFieldBlocked('cep')"
        />

        <app-input
          #inputStreet
          [control]="addressForm.controls.rua"
          label="Rua"
          tabindex="2"
          [required]="true"
          maxlength="255"
        />
      </div>

      <div class="complete-registration-form-row">
        <app-input
          #inputNumber
          inputType="number"
          inputMode="numeric"
          class="w-full sm:min-w-[180px] sm:w-[180px]"
          [control]="addressForm.controls.numero"
          label="Número"
          placeholder="EX: 2420"
          tabindex="3"
          maxlength="144"
          [required]="!noNumberAddress()"
          [optionalTag]="noNumberAddress()"
          [disabled]="isFieldBlocked('numero')"
        >
          <ng-container append>
            <div
              class="no-number-checkbox"
              matTooltip="Não possui número? Marque S/N e escreva no complemento"
              (click)="$event.preventDefault(); toogleNoNumberAddress()"
            >
              <span>S/N</span>
              <app-checkbox
                [checked]="noNumberAddress()"
                (change)="toogleNoNumberAddress()"
              >
              </app-checkbox>
            </div>
          </ng-container>
        </app-input>

        <app-input
          [control]="addressForm.controls.complemento"
          label="Complemento"
          placeholder="Ex: Casa 125-B, Br-153, Km 4"
          tabindex="4"
          [required]="noNumberAddress()"
          [optionalTag]="!noNumberAddress()"
          [disabled]="isFieldBlocked('complemento')"
          [errorMessages]="{
            required: 'Preencha corretamente seu complemento.'
          }"
        />
      </div>
      <div class="complete-registration-form-row">
        <app-input
          #inputNeighborhood
          [control]="addressForm.controls.bairro"
          label="Bairro"
          tabindex="5"
          [required]="true"
          [disabled]="isFieldBlocked('bairro')"
          [errorMessages]="{
            required: 'Preencha corretamente seu bairro.'
          }"
        />

        <app-input
          [control]="addressForm.controls.cidade"
          label="Cidade"
          tabindex="6"
          [required]="true"
          [disabled]="isFieldBlocked('cidade')"
          [errorMessages]="{
            required: 'Preencha corretamente sua cidade.'
          }"
        />
      </div>

      <div class="complete-registration-form-row">
        <app-input
          [control]="addressForm.controls.estado"
          label="Estado"
          tabindex="7"
          [required]="true"
          [disabled]="isFieldBlocked('estado')"
          [errorMessages]="{
            required: 'Preencha corretamente seu estado.'
          }"
        />

        <div class="w-full hidden sm:block"></div>
      </div>
    </div>
    } }
    <div class="app-complete-registration__content__actions">
      <app-button
        label="Voltar"
        buttonClass="h-11 bg-white text-gray-500"
        [outline]="true"
        (click)="goToStep(currentStep - 1)"
      />

      <aprova-button
        [label]="currentStep === 0 ? 'Continuar' : 'Atualizar'"
        buttonClass="button-next-step"
        [disabled]="currentStep === 0 ? !profileForm.valid : !addressForm.valid"
        (click)="currentStep === 0 ? onChangeStep(+1) : onUpdateUser()"
      />
    </div>
  </div>
</div>
