<div class="app-box-error">
  <div class="app-box-error__details">
    <div class="app-box-error__details__icon">
      <div #iconRef>
        <ng-content select="[icon]"> </ng-content>
      </div>

      @if (!isIconSlotUsed) {
      <div
        class="app-box-error__details__icon-background"
        [style]="{ background: options?.iconBackgroundColor }"
      >
        <app-icon
          [name]="iconName ?? 'shield-exclamation-outline'"
          [color]="iconColor ?? '#F43F5F'"
          [size]="iconSize ?? '24px'"
        ></app-icon>
      </div>
      }
    </div>
    <div
      class="app-box-error__details__message"
      [class]="options?.messageClasses"
      [class.app-box-error__details__message--left]="
        options?.messageHorizontalAlign === 'left'
      "
      [class.app-box-error__details__message--center]="
        options?.messageHorizontalAlign === 'center'
      "
      [class.app-box-error__details__message--right]="
        options?.messageHorizontalAlign === 'right'
      "
    >
      {{ message }}
    </div>
    @if (!options?.useHtmlInMessage) {
    <div class="app-box-error__details__description">
      {{ description }}
    </div>
    } @else {
    <div
      class="app-box-error__details__description"
      [innerHTML]="safeHtml"
    ></div>
    }
  </div>

  <ng-content select="[action]"></ng-content>
</div>
