import { AsyncPipe } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { map, tap } from 'rxjs';

import { ButtonComponent } from '@aprova-digital/design-system';

import { AppHandler } from '../../app.handler';
import { IconComponent } from '../../components/icon/icon.component';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { BoxErrorComponent } from '../../components/box-error/box-error.component';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    IconComponent,
    LoadingSpinnerComponent,
    BoxErrorComponent,
    ButtonComponent,
  ],
  templateUrl: './main.layout.component.html',
  styleUrl: './main.layout.component.scss',
})
export class MainLayoutComponent {
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _appHandler = inject(AppHandler);

  private cityHomeUrl: string = '';

  public loading = signal(true);
  public loadingConfig: {
    title: string;
    description: string;
  } = {
    title: 'Carregando...',
    description:
      'Este processo pode levar alguns segundos. Por favor, aguarde.',
  };

  public isBackButtonHome = signal(true);
  public hideBackButton = signal(false);

  public cityIdentity: string = this._route.snapshot.data['city']['identidade'];

  public hasError = signal(false);
  public errorConfig: {
    message: string;
    description: string;
    iconName?: string;
    iconColor?: string;
    iconSize?: string;
    actionText?: string;
    options?: {
      iconBackgroundColor?: string;
      messageHorizontalAlign?: 'left' | 'center' | 'right';
      useHtmlInMessage?: boolean;
    };
    action?: () => void;
  } = {
    message: '',
    description: '',
    actionText: '',
    action: () => {},
  };

  protected readonly data$ = this._route.data.pipe(
    map(({ city }) => ({
      cityLogo: city.logo,
      cityName: city.title,
      cityPhone: city.tel,
      cityIdentity: city.identidade,
    })),
    tap((data) => {
      const subdomain = data.cityIdentity.slice(0, -2);
      this.cityHomeUrl = `https://${subdomain}.aprova.com.br`;
      this.cityIdentity = data.cityIdentity;
    })
  );

  ngOnInit(): void {
    this.hideBackButton.set(false);
    this._appHandler.loadingChange().subscribe(({ loading, config }) => {
      if (config) {
        this.loadingConfig = {
          title: config.title,
          description: config.description,
        };
      }

      this.loading.set(loading);
    });

    this._appHandler.errorChange().subscribe(({ error, config }) => {
      this.hasError.set(error);

      if (config) {
        this.errorConfig = config;
      }
    });

    this._appHandler.backButtonHomeChange().subscribe((isBackButtonHome) => {
      this.isBackButtonHome.set(isBackButtonHome);
    });

    this._appHandler.hideBackButtonChange().subscribe((hideBackButton) => {
      this.hideBackButton.set(hideBackButton);
    });
  }

  async handleBackButtonOnClick() {
    this._appHandler.setLoading(true);
    if (this.isBackButtonHome()) {
      window.location.href = this.cityHomeUrl;
      return;
    }

    await this._router.navigate([`/${this.cityIdentity}`]);
    this._appHandler.setLoading(false);
    this._appHandler.clearError();
  }
}
