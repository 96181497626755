export interface Rule {
  key: string;
  label: string;
  regex: string;
  valid: boolean;
}

const PASSWORD_RULES = [
  {
    key: 'uppercase',
    label: 'A senha deverá conter letras maiúsculas (A-Z)',
    regex: '[A-Z]',
  },
  {
    key: 'lowercase',
    label: 'A senha deverá conter letras minúsculas (a-z)',
    regex: '[a-z]',
  },
  {
    key: 'number',
    label: 'A senha deverá conter numeros (0-9)',
    regex: '[0-9]',
  },
  {
    key: 'special',
    label:
      "A senha deverá conter caracteres especiais (! @ # $ % ^ & * ( ) _ + - = [ ] { } | ').",
    regex: "[-|\'|@|#|$|%|^|&|*|(|)|_|+|!|=|[|{|}|\\]]",
  },
  {
    key: 'length',
    label: 'A senha deverá conter no mínimo 8 caracteres.',
    regex: '(.){8,}',
  },
];

const CONFIRM_PASSWORD_RULES = [
  {
    key: 'match',
    label: 'As senhas devem coincidir',
    regex: '',
  },
];

export const isStrongPassword = (password: string): boolean => {
  return PASSWORD_RULES.every(({ regex }) => new RegExp(regex).test(password));
};

export const getPasswordRules = (): Rule[] => {
  return JSON.parse(
    JSON.stringify(PASSWORD_RULES.map((rule) => ({ ...rule, valid: false })))
  );
};

export const getConfirmPasswordRules = (): Rule[] => {
  return JSON.parse(
    JSON.stringify(
      CONFIRM_PASSWORD_RULES.map((rule) => ({ ...rule, valid: false }))
    )
  );
};
