import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { ResetPasswordServiceInterface } from '../interfaces/services/reset-password.service.interface';
import { ResetPasswordInput } from '../dtos/reset-password.input';

@Injectable()
export class ResetPasswordService implements ResetPasswordServiceInterface {
  private readonly _authRepository = inject(Types.AuthRepository);

  async handle(input: ResetPasswordInput): Promise<void> {
    await this._authRepository.resetPassword(input);
  }
}
