import { Component, Input, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

import { Types } from '../../../domain/types.domain';

@Component({
  selector: 'app-recaptcha',
  standalone: true,
  imports: [RecaptchaModule, RecaptchaFormsModule, ReactiveFormsModule],
  templateUrl: './recaptcha.component.html',
  styleUrl: './recaptcha.component.scss',
})
export class RecaptchaComponent {
  private readonly _settings = inject(Types.Settings);

  public readonly recaptchaSiteKey: string = this._settings.recaptcha.siteKey;

  @Input()
  public recaptchaFormControl = new FormControl('', {
    validators: [Validators.required],
  });

  @Input()
  public error: boolean = false;

  @Input()
  public errorMessages: Record<string, string> = {};

  @Input()
  public horizontalPosition: 'left' | 'center' | 'right' = 'left';

  get hasError(): boolean {
    if (this.error) return true;

    const someError = Object.values(
      this.recaptchaFormControl.errors || {}
    ).some(Boolean);

    return (
      someError &&
      this.recaptchaFormControl.invalid &&
      (this.recaptchaFormControl.dirty || this.recaptchaFormControl.touched)
    );
  }

  get errorMessageInternal(): string {
    const key = Object.keys(this.recaptchaFormControl.errors || {}).find(
      Boolean
    );
    return key && this.errorMessages[key] ? this.errorMessages[key] : '';
  }
}
