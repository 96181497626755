<div class="main-layout">
  @if (data$ | async; as data) {
  <div class="sidebar">
    <div class="sidebar__back-button" (click)="handleBackButtonOnClick()">
      @if (!hideBackButton()) {
      <app-icon name="chevron-left-outline" size="20px" color="#06929F" />
      {{ isBackButtonHome() ? "Voltar para página inicial" : "Voltar" }}
      }
    </div>
    <div class="sidebar__city-infos">
      <div class="city-logo">
        @if(data.cityLogo){
        <img [src]="data.cityLogo" width="50" height="50" alt="logo" />
        }
      </div>
      <div class="sidebar__city-infos__divider"></div>

      <div>
        <div class="sidebar__city-infos__name">
          {{ data.cityName }}
        </div>
        <div class="sidebar__city-infos__phone">
          {{ data.cityPhone }}
        </div>
      </div>
    </div>

    <div class="sidebar__footer">
      <a href="https://aprova.com.br/" target="_blank">
        <span class="text-xs text-gray-600">Powered by</span>
         <!-- Outubro Rosa <img src="/assets/images/logo-rosa.svg" alt="aprova-logo" /> -->
         <img src="/assets/images/logo.svg" alt="aprova-logo" />
      </a>
    </div>
  </div>
  }

  <div class="main-content" [class.has-error]="hasError()">
    @if (loading()) {
    <div class="main-content-loader">
      <app-loading-spinner diameter="40"></app-loading-spinner>

      <span class="app-loading__title">{{ loadingConfig.title }}</span>
      <span class="app-loading__subtitle">
        {{ loadingConfig.description }}
        <br />
        Por favor, aguarde.
      </span>
    </div>
    } @if(hasError()){
    <div class="login-error">
      <app-box-error
        [iconName]="errorConfig.iconName"
        [iconColor]="errorConfig.iconColor"
        [iconSize]="errorConfig.iconSize"
        [message]="errorConfig.message"
        [description]="errorConfig.description"
        [options]="errorConfig.options"
      >
        @if (errorConfig.action) {
        <ng-container action>
          <aprova-button
            buttonType="button"
            buttonClass="btn-box-error border"
            class="w-full"
            [label]="errorConfig.actionText ?? ''"
            (click)="errorConfig.action()"
          />
        </ng-container>
        }
      </app-box-error>
    </div>
    } @else {
    <router-outlet></router-outlet>
    }
  </div>
</div>
