import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { CreateAccountServiceInterface } from '../interfaces/services/create-account.service.interface';
import { CreateAccountInput } from '../dtos/create-account.input';

@Injectable()
export class CreateAccountService implements CreateAccountServiceInterface {
  private readonly _userRepository = inject(Types.UserRepository);

  async handle(input: CreateAccountInput) {
    return await this._userRepository.add(input);
  }
}
