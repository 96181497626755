import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { UpdateAccountServiceInterface } from '../interfaces/services/update-account.service.interface';
import { UpdateAccountInput } from '../dtos/update-account.input';

@Injectable()
export class UpdateAccountService implements UpdateAccountServiceInterface {
  private readonly _userRepository = inject(Types.UserRepository);

  async handle(input: UpdateAccountInput) {
    return this._userRepository.update(input);
  }
}
