import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { LoginByIntegrationService } from '../../../domain/auth/services/login-by-integration.service';

export class LoginByIntegrationServiceInstruction implements ClassProvider {
  provide = Types.LoginByIntegrationService;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = LoginByIntegrationService;
}
