import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { LoginByIntegrationServiceInterface } from '../interfaces/services/login-by-integration.service.interface';
import { LoginByIntegrationInput } from '../dtos/login-by-integration.input';
import { LoginByIntegrationOutput } from '../dtos/login-by-integration.output';

@Injectable()
export class LoginByIntegrationService
  implements LoginByIntegrationServiceInterface
{
  private readonly _authRepository = inject(Types.AuthRepository);

  async handle(
    input: LoginByIntegrationInput
  ): Promise<LoginByIntegrationOutput> {
    return await this._authRepository.loginByIntegration(input);
  }
}
