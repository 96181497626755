import { Injectable, signal } from '@angular/core';

import { City } from '../domain/city/entities/city.entity';
import { Observable, Subject } from 'rxjs';

interface ErrorConfig {
  message: string;
  description: string;
  actionText?: string;
  iconName?: string;
  iconColor?: string;
  iconSize?: string;
  options?: {
    iconBackgroundColor?: string;
    messageClasses?: string;
    messageHorizontalAlign?: 'left' | 'center' | 'right';
    useHtmlInMessage?: boolean;
  };
  action?: () => void;
}

interface LoadingConfig {
  title: string;
  description: string;
}

@Injectable()
export class AppHandler {
  private _city = signal<City | null>(null);

  private _loading = signal<boolean>(true);
  private _loading$ = new Subject<{
    loading: boolean;
    config?: LoadingConfig;
  }>();
  private _loadingConfig = signal<LoadingConfig>({
    title: 'Carregando...',
    description: 'Este processo pode levar alguns segundos.',
  });

  private _hideBackButton = signal(false);
  private _hideBackButton$ = new Subject<boolean>();
  private _isBackButtonHome = signal(true);
  private _isBackButtonHome$ = new Subject<boolean>();

  private _error = signal<boolean>(false);
  private _error$ = new Subject<{ error: boolean; config?: ErrorConfig }>();
  private _errorConfig = signal<ErrorConfig>({
    message: '',
    description: '',
    actionText: '',
    action: () => {},
  });

  getCity(): City | null {
    return this._city();
  }

  setCity(city: City): void {
    this._city.set(city);
  }

  loadingChange(): Observable<{ loading: boolean; config?: LoadingConfig }> {
    return this._loading$;
  }

  setLoading(loading: boolean, config?: LoadingConfig): void {
    let newConfig = {
      title: 'Carregando...',
      description: 'Este processo pode levar alguns segundos.',
    };

    if (config) {
      newConfig = config;
    }

    this._loading.set(loading);
    this._loadingConfig.set(newConfig);
    this._loading$.next({ loading, config: newConfig });
  }

  setBackButtonHome(isHome: boolean): void {
    this._isBackButtonHome.set(isHome);
    this._isBackButtonHome$.next(isHome);
  }

  backButtonHomeChange(): Observable<boolean> {
    return this._isBackButtonHome$;
  }

  hideBackButton(isHome: boolean): void {
    this._hideBackButton.set(isHome);
    this._hideBackButton$.next(isHome);
  }

  hideBackButtonChange(): Observable<boolean> {
    return this._hideBackButton$;
  }

  errorChange(): Observable<{ error: boolean; config?: ErrorConfig }> {
    return this._error$;
  }

  clearError(): void {
    this.setError(false, {
      message: '',
      description: '',
    });
  }

  setError(error: boolean, config?: ErrorConfig): void {
    let newConfig: ErrorConfig = {
      message: 'A operação não pôde ser concluída.',
      description:
        'Se o problema persistir, entre em contato com nossa equipe de suporte.',
    };

    if (config) {
      newConfig = config;
    }

    this._error.set(error);
    this._errorConfig.set(newConfig);
    this._error$.next({ error, config: newConfig });
  }
}
