import { InjectionToken } from '@angular/core';

import Settings from '../builder/instructions/settings/settings';

import { CityRepositoryInterface } from './city/interfaces/repositories/city.repository.interface';
import { AuthRepositoryInterface } from './auth/interfaces/repositories/auth.repository.interface';
import { UserRepositoryInterface } from './user/interfaces/repositories/user.repository.interface';
import { TwoFactorAuthenticationRepositoryInterface } from './two-factor/interfaces/repositories/two-facto-authentication.repository.interface';
import { CertificateRepositoryInterface } from './certificate/interfaces/repositories/certificate.repository.interface';
import { SearchCepProviderInterface } from './city/interfaces/providers/search-cep.provider.interface';
import { GetCityByIdentityServiceInterface } from './city/interfaces/services/get-city-by-identity.service.interface';
import { LoginByFormServiceInterface } from './auth/interfaces/services/login-by-form.service.interface';
import { LoginByIntegrationServiceInterface } from './auth/interfaces/services/login-by-integration.service.interface';
import { RecoverPasswordServiceInterface } from './auth/interfaces/services/recover-password.service.interface';
import { ResetPasswordServiceInterface } from './auth/interfaces/services/reset-password.service.interface';
import { ResendAccountConfirmationServiceInterface } from './auth/interfaces/services/resend-account-confirmation.service.interface';
import { VerifyChangePasswordTokenService } from './auth/services/verify-change-password-token.service';
import { VerifyUserPasswordService } from './auth/services/verify-user-password.service';
import { CreateAccountServiceInterface } from './user/interfaces/services/create-account.service.interface';
import { UpdateAccountServiceInterface } from './user/interfaces/services/update-account.service.interface';
import { TwoFactorAuthenticationServiceInterface } from './two-factor/interfaces/services/two-factor-authentication.service.interface';
import { ValidateCertificateAuthenticationServiceInterface } from './certificate/interfaces/services/validate-certificate-authentication.service.interface';
import { VerifyCreateAccountTokenServiceInterface } from './auth/interfaces/services/verify-create-account-token.service.interface';
import { WebPkiAdapter } from '../infrastructure/adapters/web-pki.adapter';

const Types = {
  Settings: new InjectionToken<Settings>('Settings'),
  CityRepository: new InjectionToken<CityRepositoryInterface>('CityRepository'),
  AuthRepository: new InjectionToken<AuthRepositoryInterface>('AuthRepository'),
  UserRepository: new InjectionToken<UserRepositoryInterface>('UserRepository'),
  TwoFactorAuthenticationRepository:
    new InjectionToken<TwoFactorAuthenticationRepositoryInterface>(
      'TwoFactorAuthenticationRepository'
    ),
  CertificateRepository: new InjectionToken<CertificateRepositoryInterface>(
    'CertificateRepository'
  ),
  SearchCepProvider: new InjectionToken<SearchCepProviderInterface>(
    'SearchCepProvider'
  ),
  GetCityByIdentityService:
    new InjectionToken<GetCityByIdentityServiceInterface>(
      'GetCityByIdentityService'
    ),
  LoginByFormService: new InjectionToken<LoginByFormServiceInterface>(
    'LoginByFormService'
  ),
  LoginByIntegrationService:
    new InjectionToken<LoginByIntegrationServiceInterface>(
      'LoginByIntegrationService'
    ),
  RecoverPasswordService: new InjectionToken<RecoverPasswordServiceInterface>(
    'RecoverPasswordService'
  ),
  ResetPasswordService: new InjectionToken<ResetPasswordServiceInterface>(
    'ResetPasswordService'
  ),
  VerifyChangePasswordTokenService:
    new InjectionToken<VerifyChangePasswordTokenService>(
      'VerifyChangePasswordTokenService'
    ),
  VerifyUserPasswordService: new InjectionToken<VerifyUserPasswordService>(
    'VerifyUserPasswordService'
  ),
  ResendAccountConfirmationService:
    new InjectionToken<ResendAccountConfirmationServiceInterface>(
      'ResendAccountConfirmationService'
    ),
  CreateAccountService: new InjectionToken<CreateAccountServiceInterface>(
    'CreateAccountService'
  ),
  UpdateAccountService: new InjectionToken<UpdateAccountServiceInterface>(
    'UpdateAccountService'
  ),
  TwoFactorAuthenticationService:
    new InjectionToken<TwoFactorAuthenticationServiceInterface>(
      'TwoFactorAuthenticationService'
    ),
  ValidateCertificateAuthenticationService:
    new InjectionToken<ValidateCertificateAuthenticationServiceInterface>(
      'ValidateCertificateAuthenticationService'
    ),
  VerifyCreateAccountTokenService:
    new InjectionToken<VerifyCreateAccountTokenServiceInterface>(
      'VerifyCreateAccountTokenService'
    ),
  WebPkiAdapter: new InjectionToken<WebPkiAdapter>('WebPkiAdapter'),
};

export { Types };
