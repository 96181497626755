import { FactoryProvider } from '@angular/core';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';

import { Types } from '../../../domain/types.domain';
import Settings from '../settings/settings';

export class SocialLoginProviderInstruction implements FactoryProvider {
  readonly provide = 'SocialAuthServiceConfig';
  readonly deps = [Types.Settings];
  readonly useFactory = (settings: Settings): SocialAuthServiceConfig => {
    return {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            settings.socialLogin.google.clientId
          ),
        },
      ],
    };
  };
}
