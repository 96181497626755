import { SearchCepProviderInterface } from '../../domain/city/interfaces/providers/search-cep.provider.interface';

import { GetCepDetailsOutput } from '../../domain/city/dtos/get-cep-details.output';

export class SearchCepProvider implements SearchCepProviderInterface {
  async handle(cep: string): Promise<GetCepDetailsOutput> {
    const viaCepResponse = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
    const data = await viaCepResponse.json();

    const response = new Object({
      rua: data.logradouro?.trim(),
      cidade: data.localidade?.trim(),
      estado: data.uf,
      bairro: data.bairro?.trim(),
    });

    return response;
  }
}
