import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { ResendAccountConfirmationServiceInterface } from '../interfaces/services/resend-account-confirmation.service.interface';
import { ResendAccountConfirmationInput } from '../dtos/resend-account-confirmation.input';

@Injectable()
export class ResendAccountConfirmationService
  implements ResendAccountConfirmationServiceInterface
{
  private readonly _authRepository = inject(Types.AuthRepository);

  async handle(input: ResendAccountConfirmationInput) {
    return await this._authRepository.resendAccountConfirmation(input);
  }
}
