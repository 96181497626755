import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { CityHttpRepository } from '../../../infrastructure/repositories/city.http.repository';

export class CityRepositoryInstruction implements ClassProvider {
  provide = Types.CityRepository;
  deps = [Types.Settings];
  useClass = CityHttpRepository;
}
