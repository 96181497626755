import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { VerifyCreateAccountTokenService } from '../../../domain/auth/services/verify-create-account-token.service';

export class VerifyCreateAccountTokenServiceInstruction
  implements ClassProvider
{
  provide = Types.VerifyCreateAccountTokenService;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = VerifyCreateAccountTokenService;
}
