import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { TwoFactorAuthenticationService } from '../../../domain/two-factor/services/two-factor-authentication.service';

export class TwoFactorAuthenticationServiceInstruction
  implements ClassProvider
{
  provide = Types.TwoFactorAuthenticationService;
  deps = [Types.Settings, Types.TwoFactorAuthenticationRepository];
  useClass = TwoFactorAuthenticationService;
}
