<div class="app-create-account" [class.welcome-message]="showWelcomeMessage()">
  @if (showWelcomeMessage()) {
  <div class="app-create-account__welcome-box">
    <div class="app-create-account__welcome-box__header">
      <p class="app-create-account__welcome-box__title">
        Que bom ter você com a gente!
      </p>
      <p class="app-create-account__welcome-box__subtitle">
        Vamos iniciar o seu cadastro de <strong>conta externa</strong>,
        exclusivo para você que é requerente.
      </p>
    </div>

    <div class="app-create-account__welcome-box__actions">
      <app-button
        label="Voltar"
        buttonClass="h-11 bg-white text-gray-500"
        [outline]="true"
        (click)="redirectToLogin()"
      />
      <aprova-button label="Continuar" (click)="startAccountCreation()" />
    </div>

    <span class="app-create-account__welcome-box__informative">
      Se você trabalha nesta organização e precisa de
      <strong>conta interna</strong>, solicite ao administrador do sistema que
      lhe envie um convite para realizar o seu cadastro. Em caso de dúvidas,
      entre em contato com o nosso suporte.
    </span>
  </div>
  } @else if (!accountCreated()) {
  <div class="app-create-account__content">
    <app-stepper
      [steps]="steps"
      [activeStep]="currentStep"
      [disabled]="saving()"
      (onStepChange)="goToStep($event)"
    />

    @switch(currentStep) { @case(0) {
    <app-personal-form
      [blockedFields]="blockedFields"
      (setStepValidation)="setStepValidation(0, $event)"
    />
    } @case (1) {
    <app-address-form
      [hideRecaptcha]="hideRecaptcha"
      [blockedFields]="blockedFields"
      [isLastStep]="hidePasswordFields"
      (setStepValidation)="setStepValidation(1, $event)"
      (changeRecaptcha)="onChangeRecaptcha($event)"
    />
    } @case(2) {
    <app-account-form
      [hideRecaptcha]="hideRecaptcha"
      [extraFields]="extraFields"
      (setStepValidation)="setStepValidation(2, $event)"
      (changeRecaptcha)="onChangeRecaptcha($event)"
    />
    } }

    <div class="app-create-account__content__actions">
      <app-button
        label="Voltar"
        buttonClass="h-11 bg-white text-gray-500"
        [outline]="true"
        (click)="goToStep(currentStep - 1)"
      />
      <aprova-button
        [label]="currentStep === steps.length - 1 ? 'Finalizar' : 'Continuar'"
        [disabled]="steps[currentStep] && !steps[currentStep].valid"
        (click)="goToStep(currentStep + 1)"
      />
    </div>
  </div>
  } @else {
  <div class="bg-white">
    <div
      class="pt-14"
      [class.pb-14]="!sendConfirmationEmail()"
      [class.pb-40]="sendConfirmationEmail()"
    >
      <div
        class="flex justify-center items-center bg-green-100 rounded-full w-16 h-16 m-auto"
      >
        <app-icon name="check-solid" size="40px" color="#22C55E" />
      </div>
      <div class="w-full mt-7">
        <p class="text-center font-medium text-2xl text-gray-900">
          Conta criada com sucesso!
        </p>
        @if (!sendConfirmationEmail()) {
        <p class="text-center font-bold text-base text-gray-700">
          Você será redirecionado para a dentro do sistema em alguns segundos.
        </p>
        }
      </div>

      @if (sendConfirmationEmail()) {
      <div class="w-full mt-3">
        <p class="text-center font-normal text-base text-gray-600">
          Enviamos um link de confirmação da conta para o e-mail cadastrado
        </p>
      </div>

      <div class="w-full mt-6">
        <p class="text-center font-bold text-base text-gray-700">
          Acesse sua caixa de e-mail para confirmar sua conta, lembre-se que não
          conseguirá ter acesso ao site sem confirmar sua conta antes.
        </p>
      </div>
      }
    </div>
  </div>
  }
</div>
