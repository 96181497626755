import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { uniqueId } from 'lodash';

import { RadioComponent } from '../radio/radio.component';

@Component({
  selector: 'app-radio-group',
  standalone: true,
  imports: [RadioComponent],
  templateUrl: './radio-group.component.html',
  styleUrl: './radio-group.component.scss',
})
export class RadioGroupComponent extends FieldType<FormlyFieldConfig> {
  @Input()
  public _id: string = uniqueId('app-radio-group-');

  @Input()
  public name: string = this._id;

  @Input()
  public label: string = '';

  @Input()
  public labelClass: string = '';

  @Input()
  public radioOptions: { label: string; value: string; disabled?: boolean }[] =
    [];

  @Input()
  public control = new FormControl<boolean | string | number>(false);

  @Input()
  public optionalTag: boolean = false;

  @Input()
  public vertical: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Output()
  onChange = new EventEmitter<string | number>();

  public override get id() {
    return this._id;
  }

  public override set id(value: string) {
    this._id = value;
  }

  ngOnInit(): void {
    Object.assign(this, this.props);
    if (this.formControl) {
      this.formControl.setValue(this.control.value, { emitEvent: false });
    }
  }

  public onValueChange(value: string | number) {
    this.control.setValue(value);
    if (this.formControl) this.formControl.setValue(value);
    this.onChange.emit(value);
  }
}
