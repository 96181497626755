import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { RECAPTCHA_LOADER_OPTIONS } from 'ng-recaptcha';
import { FormlyModule } from '@ngx-formly/core';

import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RadioComponent } from './components/radio/radio.component';

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),
    provideHttpClient(),
    provideAnimations(),
    provideEnvironmentNgxMask(),
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return `//wsrv.nl/?url=${config.src}&w=${config.width}`;
      },
    },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useValue: {
        onBeforeLoad: (url: URL) => {
          url.searchParams.set('hl', 'pt-BR');

          return { url };
        },
      },
    },
    importProvidersFrom([
      FormlyModule.forRoot({
        types: [
          {
            name: 'input',
            component: InputComponent,
          },
          {
            name: 'select',
            component: SelectComponent,
          },
          {
            name: 'radio-group',
            component: RadioGroupComponent,
          },
          {
            name: 'radio',
            component: RadioComponent,
          },
        ],
      }),
    ]),
  ],
};
