import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { VerifyChangePasswordTokenService } from '../../../domain/auth/services/verify-change-password-token.service';

export class VerifyChangePasswordTokenServiceInstruction
  implements ClassProvider
{
  provide = Types.VerifyChangePasswordTokenService;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = VerifyChangePasswordTokenService;
}
