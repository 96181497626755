import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { ValidateCertificateAuthenticationService } from '../../../domain/certificate/services/validate-certificate-authentication.service';

export class ValidateCertificateAuthenticationServiceInstruction
  implements ClassProvider
{
  provide = Types.ValidateCertificateAuthenticationService;
  deps = [Types.Settings, Types.CertificateRepository];
  useClass = ValidateCertificateAuthenticationService;
}
