import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { UserHttpRepository } from '../../../infrastructure/repositories/user.http.repository';

export class UserRepositoryInstruction implements ClassProvider {
  provide = Types.UserRepository;
  deps = [Types.Settings];
  useClass = UserHttpRepository;
}
