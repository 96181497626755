import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { GetCityByIdentityServiceInterface } from '../interfaces/services/get-city-by-identity.service.interface';
import { GetCityByIdentityInput } from '../dtos/get-city-by-identity.input';

@Injectable()
export class GetCityByIdentityService
  implements GetCityByIdentityServiceInterface
{
  private readonly _cityRepository = inject(Types.CityRepository);

  async handle(input: GetCityByIdentityInput) {
    return await this._cityRepository.getByIdentity(input.identity);
  }
}
