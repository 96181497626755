import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { CreateAccountService } from '../../../domain/user/services/create-account.service';

export class CreateAccountServiceInstruction implements ClassProvider {
  provide = Types.CreateAccountService;
  deps = [Types.Settings, Types.UserRepository];
  useClass = CreateAccountService;
}
