import { Injectable, signal } from '@angular/core';

import { CreateAccountInput } from '../../../domain/user/dtos/create-account.input';

@Injectable()
export class AccountFormHandler {
  private step = signal(0);
  private profileInfo = signal<Partial<CreateAccountInput> | null>(null);
  private addressInfo = signal<Partial<CreateAccountInput> | null>(null);
  private accountInfo = signal<Partial<Record<string, any>> | null>(null);
  private extraInfo = signal<Partial<Record<string, any>> | null>(null);

  getStep(): number {
    return this.step();
  }

  setStep(step: number): void {
    this.step.set(step);
  }

  backStep(): void {
    this.step.set(this.step() - 1);
  }

  nextStep(): void {
    this.step.set(this.step() + 1);
  }

  getProfileInfo(): Partial<CreateAccountInput> | null {
    return this.profileInfo();
  }

  setProfileInfo(data: Partial<CreateAccountInput>): void {
    this.profileInfo.set(data);
  }

  getAddressInfo(): Partial<CreateAccountInput> | null {
    return this.addressInfo();
  }

  setAddressInfo(data: Partial<CreateAccountInput>): void {
    this.addressInfo.set(data);
  }

  getAccountInfo(): Record<string, any> | null {
    return this.accountInfo();
  }

  setAccountInfo(data: Record<string, any>): void {
    this.accountInfo.set(data);
  }

  getExtraInfo(): Record<string, any> | null {
    return this.extraInfo();
  }

  setExtraInfo(data: Record<string, any>): void {
    this.extraInfo.set(data);
  }

  getCompleteInfo(): CreateAccountInput {
    return {
      ...(this.profileInfo() ?? {}),
      ...(this.addressInfo() ?? {}),
      ...(this.accountInfo() ?? {}),
      ...(this.extraInfo() ?? {}),
    } as CreateAccountInput;
  }
}
