import {
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SettingsInstruction } from '../builder/instructions/settings/settings.instruction';
import { Types } from '../domain/types.domain';

import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SocialLoginProviderInstruction } from "../builder/instructions/providers/social-login-provider.instruction";

declare let window: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoadingSpinnerComponent],
  providers: [new SettingsInstruction(), new SocialLoginProviderInstruction()],
  templateUrl: './app.component.html',
})
export class AppComponent {
  @ViewChild('chatWidget', { static: true }) chatWidget!: ElementRef;

  private readonly _settings = inject(Types.Settings);

  public loading = signal(false);

  constructor(private router: Router, private renderer2: Renderer2) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading.set(true);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading.set(false);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    // necessary check to ensure it does not add two different instances of the chat to the DOM.
    if (!window.mdChatClient) {
      this._setupMoviDeskIntegration();
    }
  }

  private _setupMoviDeskIntegration(): void {
    window.mdChatClient = this._settings.mdChatClient;
    const moviDeskIntegrationScript = this.renderer2.createElement('script');
    moviDeskIntegrationScript.src =
      'https://chat.movidesk.com/Scripts/chat-widget.min.js';
    moviDeskIntegrationScript.onload = this._loadLoginScript.bind(this);
    this.renderer2.appendChild(
      this.chatWidget.nativeElement,
      moviDeskIntegrationScript
    );
  }

  private _loadLoginScript(): void {
    const moviDeskLoginScript = this.renderer2.createElement('script');
    moviDeskLoginScript.text = `
        movideskLogin({
          name: '',
          email: '',
          codeReference: '',
          stayConnected: false,
          emptySubject: true,
          startChat: false
        })
      `;

    this.renderer2.appendChild(
      this.chatWidget.nativeElement,
      moviDeskLoginScript
    );
  }
}
