import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { TwoFactorAuthenticationHttpRepository } from '../../../infrastructure/repositories/two-factor-authentication.http.repository';

export class TwoFactorAuthenticationRepositoryInstruction
  implements ClassProvider
{
  provide = Types.TwoFactorAuthenticationRepository;
  deps = [Types.Settings];
  useClass = TwoFactorAuthenticationHttpRepository;
}
