import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { uniqueId } from 'lodash';

@Component({
  selector: 'app-radio',
  standalone: true,
  imports: [],
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.scss',
})
export class RadioComponent extends FieldType<FormlyFieldConfig> {
  @Input()
  public _id: string = uniqueId('app-radio-');

  @Input()
  public name: string = '';

  @Input({ required: true })
  public label: string = '';

  @Input({ required: true })
  public value: string | number = '';

  @Input()
  public control = new FormControl<boolean | string | number>(false);

  @Input()
  public labelClass: string = '';

  @Input()
  public leftLabel: boolean = false;

  @Input()
  public checked: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Output()
  onChange = new EventEmitter<string | number>();

  public override get id() {
    return this._id;
  }

  public override set id(value: string) {
    this._id = value;
  }

  ngOnInit(): void {
    Object.assign(this, this.props);

    this.checked = this.value === this.control.value;
    if (this.formControl) {
      this.formControl.setValue(this.checked, { emitEvent: false });
    }
    this.control.valueChanges.subscribe((val) => {
      this.checked = this.value === val;
      if (this.formControl) this.formControl.setValue(this.checked);
    });
  }

  onInputChange(val: any): void {
    this.control.setValue(this.value);
    this.onChange.emit(this.value);
    this.checked = val.target.value === this.control.value;
  }
}
