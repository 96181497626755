import { ClassProvider } from '@angular/core';

import { Types } from '../../../domain/types.domain';
import { ResendAccountConfirmationService } from '../../../domain/auth/services/resend-account-confirmation.service';

export class ResendAccountConfirmationServiceInstruction
  implements ClassProvider
{
  provide = Types.ResendAccountConfirmationService;
  deps = [Types.Settings, Types.AuthRepository];
  useClass = ResendAccountConfirmationService;
}
