<div class="app-change-password-view">
  <div class="app-change-password">
    @if(data$ | async; as data) { @if (!passwordChanged()) {

    <div class="app-change-password__message">
      <span class="app-change-password__message__title">
        {{
          type === "expired"
            ? "Ops... parece que sua senha expirou"
            : "Alterar senha"
        }}
      </span>
      <span class="app-change-password__message__subtitle">
        {{
          type === "expired"
            ? "Para sua segurança, alteramos o padrão de senhas e pedimos que a mesma seja alterada a cada 180 dias."
            : "Por favor, informe sua nova senha abaixo"
        }}
      </span>
    </div>

    <form [formGroup]="form" (submit)="onSubmit()" class="mb-4">
      <app-input
        [inputType]="!showPassword() ? 'password' : 'text'"
        [control]="form.controls.password"
        label="Senha"
        placeholder="Digite sua senha"
        (onFocus)="showPasswordRulesTooltip('password', $event)"
        (onBlur)="hidePasswordRulesTooltip()"
      >
        <ng-container append>
          <app-icon
            [name]="!showPassword() ? 'eye-off-solid' : 'eye-solid'"
            size="20px"
            color="#9ca3af"
            class="cursor-pointer"
            (click)="toggleInputPassword()"
          />
        </ng-container>
      </app-input>

      <app-input
        [inputType]="!showConfirmPassword() ? 'password' : 'text'"
        [control]="form.controls.confirmPassword"
        label="Confirmar Senha"
        placeholder="Digite sua senha"
        (onInput)="validateConfirmPasswordRules()"
        (onFocus)="showPasswordRulesTooltip('confirmPassword', $event)"
        (onBlur)="hidePasswordRulesTooltip()"
      >
        <ng-container append>
          <app-icon
            [name]="!showConfirmPassword() ? 'eye-off-solid' : 'eye-solid'"
            size="20px"
            color="#9ca3af"
            class="cursor-pointer"
            (click)="toggleInputConfirmPassword()"
          />
        </ng-container>
      </app-input>

      @if (!data.config.hideRecaptcha) {
      <app-recaptcha [recaptchaFormControl]="form.controls.recaptchaReactive" />
      }

      <aprova-button
        id="btn-reset-password-submit"
        buttonType="submit"
        class="inline-flex w-full h-10"
        buttonClass="inline-flex items-center justify-center rounded-md bg-aprova px-3 md:py-1.5 py-3 sm:text-sm leading-6 text-white shadow-sm hover:bg-aprova/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        [isLoading]="saving()"
        label="Confirmar"
        [disabled]="!isPasswordValid || !isConfirmPasswordValid"
      />
    </form>

    } @else {
    <div class="box-feedback">
      <div class="box-feedback__details">
        <div class="box-feedback__details__icon">
          <div class="box-feedback__details__icon-background">
            <app-icon name="check-solid" color="#fff"></app-icon>
          </div>
        </div>
        <div class="box-feedback__details__message">
          Senha alterada com sucesso!
        </div>
        <div class="box-feedback__details__description">
          Você será redirecionado para a página de login em alguns segundos ou
          clique no botão abaixo.
        </div>
      </div>
      <aprova-button
        buttonType="button"
        buttonClass="btn-box-error border"
        class="w-full"
        label="Ir para o login"
        (click)="redirectToLogin()"
      />
    </div>
    } }

    <div #passwordRulesTooltipRef class="box-password-rules">
      <div class="box-password-rules__title">
        Prezamos pela segurança dos seus dados e para isso, sua senha deve
        atender aos seguintes requisitos:
      </div>
      <div class="box-password-rules__content">
        <div class="box-password-rules__content__rules">
          @for(rule of rules; track rule.label) {
          <div
            class="box-password-rules__content__rules__rule"
            [class.box-password-rules__content__rules__rule--valid]="rule.valid"
          >
            <app-icon
              [name]="rule.valid ? 'check-solid' : 'exclamation-circle-outline'"
              size="20px"
              [color]="rule.valid ? '#22C55E' : '#EF4444'"
            />
            {{ rule.label }}
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
