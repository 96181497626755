import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { uniqueId } from 'lodash';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [ReactiveFormsModule, IconComponent],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent extends FieldType<FormlyFieldConfig> {
  @ViewChild('appendRef', { static: true })
  public appendRef: ElementRef<HTMLDivElement> | undefined;

  @ViewChild('errorMessageRef', { static: true })
  public errorMessageRef: ElementRef<HTMLDivElement> | undefined;

  @Input()
  private _id: string = uniqueId('app-select-');

  @Input()
  public dataTestid?: string;

  @Input()
  public label: string = '';

  @Input()
  public placeholder: string = '';

  @Input()
  public control = new FormControl<string>('');

  @Input()
  public labelClass: string =
    'block text-sm font-medium leading-6 text-gray-900';

  @Input()
  public selectOptions: { label: string; value: string; disabled?: boolean }[] =
    [];

  @Input()
  public width: string = 'auto';

  @Input()
  public height: string = '38px';

  @Input()
  public readonly: boolean = false;

  @Input()
  public squared: boolean = false;

  @Input()
  public filled: boolean = false;

  @Input()
  public shadow: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public clearButton: boolean = false;

  @Input()
  public required: boolean = false;

  @Input()
  public optionalTag: boolean = false;

  @Input()
  public error: boolean = false;

  @Input()
  public errorMessages: Record<string, string> = {};

  @Output()
  public onChange = new EventEmitter<string | number>();

  public override get id() {
    return this._id;
  }

  public override set id(value: string) {
    this._id = value;
  }

  get hasError(): boolean {
    if (this.error) return true;

    const someError = Object.values(this.control.errors || {}).some(Boolean);

    return (
      someError &&
      this.control.invalid &&
      (this.control.dirty || this.control.touched)
    );
  }

  get errorMessageInternal(): string {
    const key = Object.keys(this.control.errors || {}).find(Boolean);
    return key && this.errorMessages[key] ? this.errorMessages[key] : '';
  }

  get isAppendSlotUsed(): boolean {
    return !!this.appendRef?.nativeElement.innerHTML;
  }

  get isErrorMessageSlotUsed(): boolean {
    return !!this.errorMessageRef?.nativeElement.innerHTML;
  }

  get selectedLabel(): string {
    if (!this.control.value) return this.placeholder;
    if (this.control.value === '') return this.placeholder;

    const selected = this.selectOptions.find(
      (opt) => opt.value === this.control.value
    );
    return !selected ? this.placeholder : selected.label;
  }

  public isOpen = signal(false);

  ngOnInit(): void {
    Object.assign(this, this.props);

    if (this.disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
      if (this.formControl) {
        this.formControl.setValue(this.control.value, { emitEvent: false });
      }
    }
  }

  public clear(): void {
    this.control.setValue('');
  }

  public onHandleMenuOpen(): void {
    this.isOpen.update((prev) => !prev);
  }

  public closeMenu(): void {
    this.isOpen.set(false);
  }

  public onSelectOption(option: { label: string; value: string }): void {
    this.control.setValue(option.value);
    if (this.formControl) {
      this.formControl.setValue(option.value);
    }
    this.onChange.emit(option.value);
    this.closeMenu();
  }
}
