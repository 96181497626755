import { Injectable, inject } from '@angular/core';

import { Types } from '../../types.domain';

import { RecoverPasswordInput } from '../dtos/recover-password.input';
import { RecoverPasswordServiceInterface } from '../interfaces/services/recover-password.service.interface';

@Injectable()
export class RecoverPasswordService implements RecoverPasswordServiceInterface {
  private readonly _authRepository = inject(Types.AuthRepository);

  async handle(input: RecoverPasswordInput): Promise<void> {
    await this._authRepository.recoverPassword(input);
  }
}
