import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { UserRepositoryInterface } from '../../domain/user/interfaces/repositories/user.repository.interface';
import { Types } from '../../domain/types.domain';
import { User } from '../../domain/user/entities/user.entity';
import { CreateAccountInput } from '../../domain/user/dtos/create-account.input';
import { UpdateAccountInput } from '../../domain/user/dtos/update-account.input';

@Injectable()
export class UserHttpRepository implements UserRepositoryInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async add(data: CreateAccountInput): Promise<User> {
    return lastValueFrom(
      this._http.post<User>(`${this._settings.usersUrl.v2}/insert`, data)
    );
  }

  async update(data: UpdateAccountInput): Promise<User> {
    const { accessToken, ...body } = data;
    return lastValueFrom(
      this._http.patch<User>(`${this._settings.usersUrl.v2}/update/my`, body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    );
  }
}
