<div>
  @if(loading()){
  <div class="app-loading">
    <app-loading-spinner diameter="36" />
    <span class="app-loading__title">Carregando...</span>
    <span class="app-loading__subtitle">
      Este processo pode levar alguns segundos. <br />
      Por favor, aguarde.
    </span>
  </div>
  }

  <router-outlet></router-outlet>

  <div #chatWidget></div>
</div>
