import { Component, EventEmitter, Input, Output } from '@angular/core';
import { uniqueId } from 'lodash';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  @Input()
  public id: string = uniqueId('app-stepper-');

  @Input()
  public stepperType: 'simple' | 'bullets' | 'panels' = 'simple';

  @Input()
  public steps: Array<{
    label: string;
    description?: string;
    valid: boolean;
    active?: boolean;
  }> = [];

  @Input()
  public activeStep: number = 0;

  @Input()
  public disabled: boolean = false;

  @Input()
  public hideStepNumber: boolean = false;

  @Output()
  public onStepChange = new EventEmitter<number>();

  get isActiveStepValid(): boolean {
    return this.steps[this.activeStep].valid;
  }

  goToStep(stepNumber: number): void {
    if (this.disabled || stepNumber > this.steps.length - 1) {
      return;
    }

    this.onStepChange.emit(Math.max(stepNumber, 0));
  }

  padWithLeadingZeros(num: number, length = 2) {
    return String(num).padStart(length, '0');
  }
}
