<div class="app-input">
  @if (label) {
  <div class="box-label">
    <label
      [for]="id"
      data-testid="dataTestid ? 'textFieldLabel-' + dataTestid : undefined"
      [class]="labelClass"
    >
      {{ label }}
    </label>

    @if (optionalTag) {
    <span class="optional-tag ml-1">(opcional)</span>
    }
  </div>
  }

  <div
    class="box-input"
    [class.border]="!filled"
    [class.filled]="filled"
    [class.disabled]="disabled"
    [class.input-error]="hasError"
    [class.rounded-lg]="!squared"
    [style.width]="width || 'auto'"
    [style.height]="height || '38px'"
    [style.box-shadow]="shadow ? '0px 5px 5px -5px rgba(0, 0, 0, 0.1)' : ''"
  >
    <div #prependRef>
      <ng-content select="[prepend]"></ng-content>
    </div>

    <input
      #input
      [id]="id"
      [type]="inputType"
      [attr.inputmode]="inputMode"
      [placeholder]="placeholder"
      [class]="inputClass"
      [attr.data-testid]="dataTestid ? 'textField-' + dataTestid : undefined"
      [formControl]="control"
      [required]="required"
      [attr.maxlength]="maxLength"
      [readonly]="readonly"
      [mask]="mask"
      (focus)="onFocus.emit($event)"
      (blur)="onBlur.emit($event)"
      (input)="onInput.emit($event)"
      (change)="onChange.emit($event)"
    />

    @if (clearButton && control.value && control.value.length) {
    <div class="absolute right-1" [class.right-5]="isAppendSlotUsed">
      <app-icon name="x-outline" class="cursor-pointer" (click)="clear()" />
    </div>
    }

    <div #appendRef class="absolute right-1">
      <ng-content select="[append]"></ng-content>
    </div>
  </div>

  <div class="box-error-message">
    <div #errorMessageRef>
      <ng-content select="[error-message]"></ng-content>
    </div>

    @if (hasError && !isErrorMessageSlotUsed) {
    <p error-message class="text-sm text-red-600 dark:text-red-500">
      {{ errorMessageInternal }}
    </p>
    }
  </div>
</div>
